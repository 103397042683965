import { Serializable } from '../Serializable';

export class CurrencySort extends Serializable {
  sortDir: string;
  sortBy: string;

  /**
   * Возвращает параметры
   *
   */
  toHttpParams(): any {
    const result = {};

    if (this.sortDir) {
      result['sortDir'] = this.sortDir;
    }
    if (this.sortBy) {
      result['sortBy'] = this.sortBy;
    }
    return result;
  }
}
