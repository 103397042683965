/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable quote-props */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@app/env';
import { api } from 'src/app/config';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Currency, Customer, PaymentOption, User } from '../models';
import { dictionaryTranslator } from 'src/app/utility';
import { ShopData } from '../models/ShopData';
import { v4 as uuidv4 } from 'uuid';
import { merge } from 'lodash';
import { WidgetLocalizationService } from './widget.localization.service';

export const WIDGET_TOKEN = 'WIDGET_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const WIDGET_TOKEN_EXPIRED = 'WIDGET_TOKEN_EXPIRED';
export const WIDGET_UUID = 'WIDGET_UUID';
export const WIDGET_CART_DATA = 'WIDGET_CART_DATA';
@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  /**
   * Счетчик
   *
   * @type {number}
   */
  counter = 30;
  /**
   * http клиент
   *
   * @type {HttpClient}
   */
  private http: HttpClient;
  /**
   * Сабджект для хранения токена
   *
   * @type {BehaviorSubject<string>}
   */
  private tokenChanged = new BehaviorSubject<string>(null);
  /**
   * Текущий пользователь
   */
  private _currentUser: User;

  /**
   * Bearer токен
   */
  private token: string;
  /**
   * Токен обновления
   */
  private refreshToken: string;
  /**
   * Uuid сессии
   *
   * @type {string}
   */
  private sessionId: string;

  constructor(
    private handler: HttpBackend,
    private localizationService: WidgetLocalizationService
  ) {
    this.http = new HttpClient(handler);
    this.restoreUuid();
    if (!this.sessionId) {
      this.generateUuid();
    }
  }

  /**
   * Авторизация клиента
   *
   * @param login логин
   * @param password пароль
   * @returns Наблюдателя за выполнением запроса
   */
  authorize(login: string, password: string): Observable<any> {
    const base64 = window.btoa('portal-frontend:secret');
    const header = new HttpHeaders({
      Authorization: `Basic ${base64}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const body = new URLSearchParams();
    body.set('grant_type', 'password');
    body.set('username', login);
    body.set('password', password);
    return this.http.post(`${this.getAuthUrl()}`, body, { headers: header });
  }

  /**
   * Эндпоинт экспресс регистрации
   *
   * @param login логин
   * @param name имя
   * @param surname фамилия
   * @returns Наблюдателя за выполнением запроса
   */
  registration(login: string, name: string, surname: string) {
    const locale = this.localizationService.getCurrentLanguage();
    return this.http.post(
      `${this.getExpressRegistrationPath()}`,
      {
        login,
        isRegistration: true,
        surname,
        name,
        locale: locale.localeId
      },
      this.getHttpOptionsWithoutAuth()
    );
  }

  /**
   * Сохранение токена
   *
   * @param authInfo информация об авторизации
   */
  saveToken(authInfo: any) {
    return new Observable(observer => {
      this.token = authInfo['access_token'];
      this.refreshToken = authInfo['refresh_token'];
      this.tokenChanged.next(this.token);
      const now = new Date();
      now.setHours(now.getHours() + 3);
      sessionStorage.setItem(WIDGET_TOKEN, this.token);
      sessionStorage.setItem(REFRESH_TOKEN, this.refreshToken);
      sessionStorage.setItem(WIDGET_TOKEN_EXPIRED, `${now.getTime()}`);
      observer.next(this.token);
    });
  }

  /**
   * Восстановление токена после перезагрузки страницы
   */
  restoreToken() {
    const expireDate = sessionStorage.getItem(WIDGET_TOKEN_EXPIRED);
    const tokenStorage = sessionStorage.getItem(WIDGET_TOKEN);
    const refreshTokenStorage = sessionStorage.getItem(REFRESH_TOKEN);
    if (!expireDate || !tokenStorage) {
      return;
    }
    const currentTime = new Date();
    if (currentTime.getTime() > Number(expireDate)) {
      this.clearStorage();
    } else {
      this.token = tokenStorage;
      this.refreshToken = refreshTokenStorage;
    }
  }

  /**
   * Проверка истек ли токен
   *
   * @returns Признак истекла ли сессия
   */
  checkIfExpired(): boolean {
    const expireDate = sessionStorage.getItem(WIDGET_TOKEN_EXPIRED);
    if (!expireDate) {
      return false;
    }
    const currentTime = new Date();
    return currentTime.getTime() > Number(expireDate);
  }

  /**
   * Проверка на наличие токена
   *
   * @returns признак что пользователь залогинен
   */
  isLoggedIn() {
    return this.token !== null && this.token !== undefined;
  }
  /**
   * Логаут
   */
  logout() {
    this.token = null;
    this.refreshToken = null;
    this.tokenChanged.next(this.token);
    this.clearStorage();
    this.clearUuid();
    this.generateUuid();
  }

  /**
   * Очистка хранилища
   */
  clearStorage() {
    console.log('clear storage');
    sessionStorage.removeItem('WIDGET_TOKEN');
    sessionStorage.removeItem('REFRESH_TOKEN');
    sessionStorage.removeItem('WIDGET_TOKEN_EXPIRED');
    localStorage.removeItem('WIDGET_USER_NAME');
    localStorage.removeItem('WIDGET_USER_ISPHONE');
    localStorage.removeItem('DATA_IS_SENDED');
    localStorage.removeItem('CHECKBOX_STATE');
    localStorage.removeItem('WIDGET_COLLAPSE_FIRST_TIME');
    localStorage.removeItem('WIDGET_COLLAPSE_SECOND_TIME');
    localStorage.removeItem('WIDGET_LOCALIZATION');
    localStorage.removeItem(WIDGET_CART_DATA);
  }

  /**
   * Наблюдатель за изменением токена
   *
   * @returns наблюдателя за изменением токена
   */
  getChangedTokenObservable() {
    return this.tokenChanged.asObservable();
  }

  /**
   * Получение данных покупателя
   *
   * @returns Наблюдателя за получением данных покупателя
   */
  getCustomer(): Observable<Customer> {
    return this.http
      .get<Customer>(this.getCustomerPath(), this.getHttpOptions())
      .pipe(
        map(customer => {
          if (!customer) {
            customer = new Customer();
          }
          if (customer.paymentDetails) {
            customer.paymentDetails = customer.paymentDetails.map(
              paymentDetail =>
                new PaymentOption(
                  paymentDetail.paymentDetailsId,
                  paymentDetail.currencyCode,
                  paymentDetail.currencyLetterCode,
                  paymentDetail.paymentType,
                  paymentDetail.recipientName,
                  paymentDetail.recipientSurname,
                  paymentDetail.recipientNumber,
                  paymentDetail.expirationDate,
                  paymentDetail.actual
                )
            );
          }
          return customer;
        })
      );
  }

  /**
   * Запрос на получение пользователя
   *
   * @description Выполняет запрос, запускает простановку и обновление параметров пользователя,
   * @returns Наблюдателя за получением пользователя
   */
  obtainAuthUser(): Observable<User> {
    return new Observable(() => {
      this.http
        .get<User>(this.getAuthUserPath(), this.getHttpOptions())
        .subscribe(user => {
          this._currentUser = user;
        });
    });
  }

  /**
   * Запрос на получение пользователя
   *
   * @description Выполняет запрос, запускает простановку и обновление параметров пользователя,
   * @returns Наблюдателя за получением пользователя
   */
  obtainRegAuthUser(): Observable<any> {
    return this.http.get<User>(
      this.getRegAuthUserPath(),
      this.getHttpOptions()
    );
  }
  /**
   * Генерация UUID сессии
   */
  generateUuid() {
    this.sessionId = uuidv4();
    localStorage.setItem(WIDGET_UUID, this.sessionId);
  }

  /**
   * Восстановление UUID сессии
   */
  restoreUuid() {
    const uuid = localStorage.getItem(WIDGET_UUID);
    if (!uuid) {
      return;
    }
    this.sessionId = uuid;
  }

  /**
   * Удаление UUID
   */
  clearUuid() {
    this.sessionId = null;
    localStorage.removeItem(WIDGET_UUID);
  }
  /**
   * Получение списка курсов валют с опциями авторизации
   *
   * @returns Наблюдателя за получением списка курсов валют
   */
  public obtainCurrency(): Observable<Currency[]> {
    return this.obtainCurrencyWithHttpOptions({});
  }

  /**
   * Получение НДС
   *
   * @returns Наблюдателя за получением НДС
   */
  public getVat(): Observable<any> {
    return this.http.get(this.getVatPath());
  }

  /**
   * Инициализация восстановления пароля
   *
   * @param phoneOrEmail телефон или эмейл
   * @returns Наблюдателя за результатом запроса
   */
  initRestoreProcess(phoneOrEmail: string): Observable<any> {
    const locale = this.localizationService.getCurrentLanguage();

    return this.http.post<void>(
      this.getRestoreUrl(),
      {
        email: phoneOrEmail,
        locale: locale.localeId
      },
      this.getHttpOptionsWithoutAuth()
    );
  }

  /**
   * Запрос на сброс пароля
   *
   * @param code код подтверждения из смс
   * @param phone номер телефона
   * @param password пароль
   * @param shopId id магазина
   * @returns observable объект запроса
   */
  confirmRegisterBySmsCode(
    code: string,
    phone: string,
    password: string,
    shopId: string
  ): Observable<void> {
    return this.http.post<void>(
      this.getConfirmChangePasswordBySmsCodePath(code, phone, shopId),
      { password },
      this.getHttpOptionsWithoutAuth()
    );
  }

  /**
   * Запрос на подтверждение регистрации или сброса пароля
   *
   * @param code код подтверждения из смс
   * @param phone номер телефона
   * @param password пароль
   * @param shopId id магазина
   * @returns observable объект запроса
   */
  confirmExpressRegisterBySmsCode(
    code: string,
    phone: string,
    password: string,
    shopId: string
  ): Observable<void> {
    return this.http.post<void>(
      this.getConfirmRegisterBySmsCodePath(code, phone, shopId),
      { password },
      this.getHttpOptionsWithoutAuth()
    );
  }
  /**
   * Повторная отправка смс
   *
   * @param login логин
   * @param registration признак регистрации
   * @returns observable объект запроса
   */
  resendSms(login: string, registration: boolean = false): Observable<void> {
    const locale = this.localizationService.getCurrentLanguage();
    const body = {
      login,
      registration,
      locale: locale.localeId
    };
    return this.http.post<void>(this.getResendSmsPath(), body);
  }
  /**
   * Http Опции
   */
  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      })
    };
  }

  /**
   * Http Опции без авторизации
   */
  getHttpOptionsWithoutAuth() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }
  /**
   * Отсылка данных собранных с сайта магазина
   *
   * @param shopData - данные
   */
  sendShopData(shopData: ShopData) {
    let resultObject;
    if (!this.sessionId) {
      return of();
    }
    shopData.sessionId = `${this.sessionId}`;
    const data = this.getSavedData();
    if (shopData.order.number) {
      console.log('clear uuid before send');
      this.clearUuid();
      const cartSaved = localStorage.getItem('WIDGET_CART_SUM');
      if (cartSaved) {
        shopData.order.commodities = JSON.parse(cartSaved).commodities;
      }
    }
    if (data) {
      const dataObject = JSON.parse(data);
      if (dataObject !== shopData) {
        resultObject = merge(dataObject, shopData);
        this.saveData(resultObject);
        return this.sendRequestForShopData(resultObject);
      }
      return of();
    }
    this.saveData(shopData);
    resultObject = shopData;
    return this.sendRequestForShopData(resultObject);
  }

  /**
   * Отсылка данных собранных с сайта магазина
   *
   * @param resultObject - объект с данными
   * @param shopData - данные
   */
  sendRequestForShopData(resultObject: any) {
    if (
      resultObject.order &&
      resultObject.order.sum === null &&
      resultObject.order.commodities.length === 0
    ) {
      return of();
    }
    const locale = this.localizationService.getCurrentLanguage();
    resultObject.locale = locale.localeId;
    if (resultObject.deliveryAddress?.city) {
      resultObject.customer.city = resultObject.deliveryAddress?.city;
    }
    if (resultObject.deliveryAddress?.country) {
      resultObject.customer.country = resultObject.deliveryAddress?.country;
    }
    if (resultObject.paymentType === '') {
      resultObject.paymentType = null;
    }
    return this.http
      .post(
        this.getSendDataPath(),
        resultObject,
        this.isLoggedIn()
          ? this.getHttpOptions()
          : this.getHttpOptionsWithoutAuth()
      )
      .pipe(
        map(res => {
          // На первое время триггер завершения сессии - наличие в заказе id
          // Очищаем старый uuid и генерируем новый
          if (resultObject.order && resultObject.order.number) {
            console.log('clear uuid after');
            localStorage.setItem(
              'WIDGET_ORDER_NUMBER',
              resultObject.order.number
            );
            this.clearUuid();
            this.clearSavedData();
            setTimeout(() => {
              this.generateUuid();
              this.clearSavedData();
            }, 1000);
          }
          return res;
        })
      );
  }

  /**
   * Получение данных модели
   *
   * @returns
   */
  getSavedData() {
    return localStorage.getItem(WIDGET_CART_DATA);
  }

  /**
   * Сохранения модели для отправки
   *
   * @param shopData данные собранные из магазина
   */
  saveData(shopData: ShopData) {
    localStorage.setItem(WIDGET_CART_DATA, JSON.stringify(shopData));
  }

  /**
   * Очистка данных модели
   *
   * @returns
   */
  clearSavedData() {
    localStorage.removeItem(WIDGET_CART_DATA);
    localStorage.removeItem('WIDGET_CART_SUM');
    localStorage.removeItem('WIDGET_CART_REFUND_RUB');
  }

  /**
   * Получение локализаций
   *
   * @returns Наблюдателя за получением локализаций
   */
  getLocalizations() {
    return this.http
      .get(this.getLocalizationPath(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=utf-8'
        })
      })
      .pipe(
        map((response: any) => {
          if (!response) {
            return [];
          }
          const items = response.items;
          const keys = Object.keys(response.items);
          const locales = [];
          keys.forEach(key => {
            const itemKeys = Object.keys(items[key]);
            const mappedObject = {
              key
            };
            itemKeys.forEach(itemKey => {
              Object.assign(mappedObject, {
                [itemKey]: items[key][itemKey]
              });
            });
            locales.push(mappedObject);
          });
          return locales;
        })
      );
  }

  /**
   * Получение расположения виджета по id магазина
   *
   * @param shopId id магазина
   * @returns Наблюдателя за запросом
   */
  getWidgetLocation(shopId: string) {
    return this.http.get(
      this.getWidgetLocationPath(shopId),
      this.isLoggedIn()
        ? this.getHttpOptions()
        : this.getHttpOptionsWithoutAuth()
    );
  }

  /**
   * Получение списка курсов валют с http опциями, переданными в функцию
   *
   * @param httpOptions опции http
   * @returns Наблюдателя за получением списка курсов валют
   */
  private obtainCurrencyWithHttpOptions(
    httpOptions: object
  ): Observable<Currency[]> {
    return this.http
      .get<any>(this.getAllCurrenciesPath(), httpOptions)
      .pipe(map(currencies => dictionaryTranslator(currencies, 'ru')));
  }

  /**
   * Генерация URL для получения НДС
   *
   * @returns URL для получения НДС
   */
  private getVatPath() {
    return environment.baseAccountingApiUrl + api.taxCalculator.vat;
  }
  /**
   * Генерация URL списка курсов валют
   *
   * @returns URL списка курсов валют
   */
  private getAllCurrenciesPath(): string {
    return `https://taxfree-media.wavea.cc/media/currency/currency.json?salt=${new Date().getTime()}`;
  }
  /**
   * Генерация URL для авторизации
   *
   * @returns URL для авторизации
   */
  private getAuthUrl() {
    return environment.widgetAuthService + api.customer.oauthToken;
  }

  /**
   * Генерация URL для получения данных покупателя
   *
   * @returns URL для получения данных покупателя
   */
  private getCustomerPath(): string {
    return environment.widgetPortalService + api.widget.customer;
  }

  /**
   * Генерация URL для отправления данных из магазина
   *
   * @returns URL для отправления данных из магазина
   */
  private getSendDataPath() {
    return environment.widgetStoreService + api.widget.sendPath;
  }

  /**
   * Генерация URL для запроса текущего пользователя
   *
   * @returns URL для запроса текущего пользователя
   */
  private getAuthUserPath(): string {
    return environment.widgetPortalService + api.customer.currentUser;
  }

  /**
   * Генерация URL для запроса текущего пользователя после экспресс регистрации
   *
   * @returns URL для запроса текущего пользователя
   */
  private getRegAuthUserPath(): string {
    return environment.widgetPortalService + api.customer.userExpress;
  }
  /**
   * URL эндпоинта сброса пароля
   */
  private getRestoreUrl(): string {
    return environment.widgetPortalService + api.customer.restore;
  }

  /**
   * URL эндпоинта подтверждения изменения пароля через смс-код
   *
   * @param code код подтверждения
   * @param login логин
   * @param shopId id магазина
   */
  private getConfirmChangePasswordBySmsCodePath(
    code: string,
    login: string,
    shopId: string
  ): string {
    return (
      environment.widgetPortalService +
      api.widget.confirmRegistration
        .replace('{code}', code)
        .replace('{login}', login)
        .replace('{shopId}', shopId)
        .replace(/\+/g, '%2B')
    );
  }

  /**
   * URL эндпоинта подтверждения изменения пароля через смс-код
   *
   * @param code код подтверждения
   * @param login логин
   * @param shopId id магазина
   */
  private getConfirmRegisterBySmsCodePath(
    code: string,
    login: string,
    shopId
  ): string {
    return (
      environment.widgetPortalService +
      api.widget.confirmRegistration
        .replace('{code}', code)
        .replace('{login}', login)
        .replace('{shopId}', shopId)
        .replace(/\+/g, '%2B')
    );
  }

  /**
   * URL эндпоинта повторной отправки смс
   */
  private getResendSmsPath(): string {
    return environment.widgetPortalService + api.customer.resendSms;
  }
  /**
   * URL эндпоинта экспресс регистрации
   */
  private getExpressRegistrationPath(): string {
    return environment.widgetPortalService + api.customer.expressRegistration;
  }
  /**
   * Генерация URL для получения конфига виджета
   *
   * @returns URL для получения конфига виджета
   */
  private getGlobalWidgetPath() {
    return environment.widgetPortalService + api.widget.widgetPath;
  }
  /**
   * Генерация URL для получения размещения виджета
   *
   * @param shopId id магазина
   * @returns URL для получения размещения виджета
   */
  private getWidgetLocationPath(shopId: string) {
    return (
      environment.widgetPortalService +
      api.widget.widgetLocationPath.replace('{shopId}', shopId)
    );
  }
  /**
   * Генерация URL для получения локализаций
   *
   * @returns URL для получения локализаций
   */
  private getLocalizationPath() {
    return `https://taxfree-media.wavea.cc/media/tax-free-widget/widget-locales.json?timestamp=${Date.now()}`;
  }
}
