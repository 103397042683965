import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'lodash';

import { NotificationsService } from 'src/app/core/services';
import { AuthService } from 'src/app/core/services/auth.service';
import { CUSTOMER } from '../../../../core';
/**
 * Компонент иконки уведомлений
 */
@Component({
  selector: 'app-indicator-notifications',
  templateUrl: './indicator-notifications.component.html',
  styleUrls: ['./indicator-notifications.component.scss']
})
export class IndicatorNotificationsComponent implements OnInit, OnDestroy {
  /**
   * Наблюдатель за событием подгрузки уведомлений
   *
   * @type {Observable<any>}
   */
  notifyListObserver: Observable<any>;
  /**
   * Наблюдатель за появлением нового уведомления
   *
   * @type {Observable<any>}
   */
  notifyObserver: Observable<any>;
  /**
   * Счетчик уведомлений
   *
   * @type {number}
   */
  notifyCount = 0;
  /**
   * Признак отображения индикатора о наличии непрочитаных уведомлений
   *
   * @type {boolean}
   */
  showIndicator = false;
  /**
   * Признак роли покупателя
   *
   * @type {boolean}
   */
  isCustomerRole = false;

  constructor(
    private notifyService: NotificationsService,
    private authService: AuthService
  ) {}

  /**
   * Хук инициализации
   *
   * @description подписывается на события получения ролей и уведомлений
   */
  ngOnInit() {
    this.authService.userRoleChange.subscribe(() => {
      if (!this.authService.currentUserRole) {
        return;
      }
      this.isCustomerRole = this.authService.currentUserRole.includes(
        this.getCustomerRoleName()
      );
      this.subscribeNotifications();
    });
  }

  /**
   * Хук финализации
   *
   * @description отписывается от события получения новой роли
   */
  ngOnDestroy() {
    this.authService.userRoleChange.unsubscribe();
  }

  /**
   * Подписка на получения списка уведомлений
   */
  private subscribeNotifications() {
    this.notifyListObserver = this.notifyService.getNotifyListObserver();
    this.notifyObserver = this.notifyService.getNotifyObserver();

    this.notifyListObserver.subscribe(notifications => {
      this.notifyCount = filter(notifications.elements, ['read', false]).length;
    });
    this.notifyObserver.subscribe(() => this.notifyCount++);
    this.showIndicator = true;
  }

  /**
   * Получение названия роли покупателя
   *
   * @returns название роли покупателя
   */
  private getCustomerRoleName(): string {
    return CUSTOMER;
  }
}
