import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { ADMIN, OPERATOR, PAYMENT_OPERATOR } from '../constants';
import { CommonOptions, Metrika } from 'ng-yandex-metrika';
import { environment } from '@app/env';

/**
 * Сервис для работы с яндекс метрикой
 */
@Injectable({
  providedIn: 'root',
})
export class MetrikaService {
  /**
   * Отключение метрики для операторов, админа
   */
  isDisabledMetrikaByUser: boolean | null = null;
  /**
   * Роли, для которых отключаем я.метрику
   */
  roles = new Set([OPERATOR, PAYMENT_OPERATOR, ADMIN]);

  constructor(
    private authService: AuthService,
    private metrika: Metrika,
  ) {}

  /**
   * Аналог reachGoal Я.Метрики с нашими проверками
   *
   * @param type type
   * @param options options
   * @param counterId counterId
   */
  reachGoal(type: string, options?: CommonOptions, counterId?: number) {
    if (!environment.yandexMetrikaOn || this.checkUser()) {
      return;
    }

    return this.metrika.reachGoal(type, options, counterId);
  }

  /**
   * Проверка текующего юзера на соответствие роли
   */
  private checkUser() {
    if (this.isDisabledMetrikaByUser !== null) {
      return this.isDisabledMetrikaByUser;
    }

    const isDisabledMetrikaByUser = this.authService.currentUserRole.some(
      (role) => this.roles.has(role),
    );

    this.isDisabledMetrikaByUser = isDisabledMetrikaByUser;

    return isDisabledMetrikaByUser;
  }
}
