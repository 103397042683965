import { ACTUAL, NOT_ACTUAL } from '../constants';
import { Currency } from './Currency/Currency';

const actualIdentifier = ACTUAL;
const notActualIdentifier = NOT_ACTUAL;

export class PaymentOption {
  paymentDetailsId?: string;
  currencyCode: number;
  currencyLetterCode: string;
  createdAt: Date;
  paymentType: string; // VISA, MasterCard, UnionPay, WeChatPay, AMEX, JCB, МИР, AliPay;
  recipientName: string;
  recipientSurname: string;
  recipientNumber: string;
  expirationDate: string;
  actual: string;
  currency?: Currency;

  constructor(
    paymentDetailsId: string,
    currencyCode: number,
    currencyLetterCode: string,
    paymentType: string,
    recipientFirstName: string,
    recipienLastName: string,
    recipientNumber: string,
    expirationDate?: string,
    actual?: string
  ) {
    this.paymentDetailsId = paymentDetailsId;
    this.currencyCode = currencyCode;
    this.currencyLetterCode = currencyLetterCode;
    this.paymentType = paymentType;
    this.recipientName = recipientFirstName;
    this.recipientSurname = recipienLastName;
    this.recipientNumber = recipientNumber;
    this.expirationDate = expirationDate;

    actual === actualIdentifier ? this.setDefault() : this.setNotDefault();
  }

  /**
   * Установка значения используется по умолчанию
   */
  public setDefault(): void {
    this.actual = actualIdentifier;
  }

  /**
   * Установка значения не используется по умолчанию
   */
  public setNotDefault(): void {
    this.actual = notActualIdentifier;
  }
  /**
   * Установка валюты
   *
   * @param currency валюта
   */
  public setCurrency(currency: Currency): void {
    this.currency = currency;
  }
}
