import { UntypedFormControl } from '@angular/forms';
import { isNil } from 'lodash';

enum PasswordStrength {
  WRONG_PASSWORD = 'WRONG_PASSWORD',
  WEAK = 'WEAK',
  NORMAL = 'NORMAL',
  GOOD = 'GOOD'
}
/**
 * Валидатор надежности пароля
 *
 * @param password пароль
 */
export function passwordStrengthValidator(
  password: UntypedFormControl
): string {
  const value = password.value;
  if (isNil(value)) {
    return;
  }
  let strength: PasswordStrength = PasswordStrength.WRONG_PASSWORD;
  if (
    (!isNil(value.match(/^(?=.*[a-z0-9а-я])/g)) &&
      isNil(value.match(/(?=.*[A-ZА-Я])/g))) ||
    (!isNil(value.match(/^(?=.*[A-Zа-я0-9])/g)) &&
      isNil(value.match(/(?=.*[a-zа-я])/g)))
  ) {
    strength = PasswordStrength.WEAK;
  }
  if (
    (!isNil(
      value.match(/^(?=.*[!@#\$%\^&\*`;:\?\(\)\-_=\{\}\[\]\\\/><\.,'|~])/g)
    ) &&
      !isNil(value.match(/^(?=.*[0-9])/g))) ||
    (!isNil(value.match(/^(?=.*[a-zа-я])/g)) &&
      !isNil(value.match(/^(?=.*[A-ZА-Я])/g)) &&
      !isNil(value.match(/^(?=.*[0-9])/g)))
  ) {
    strength = PasswordStrength.NORMAL;
  }
  if (
    !isNil(
      value.match(/^(?=.*[!@#\$%\^&\*`;:\?\(\)\-_=\{\}\[\]\\\/><\.,'|~])/g)
    ) &&
    !isNil(value.match(/^(?=.*[a-zа-я])/g)) &&
    !isNil(value.match(/(?=.*[A-ZА-Я])/g)) &&
    !isNil(value.match(/^(?=.*[0-9])/g))
  ) {
    strength = PasswordStrength.GOOD;
  }
  return strength.toString();
}
