import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { WidgetService } from '@app/core/services/widget.service';
import {
  DESCRIPTION_EMAIL,
  DESCRIPTION_PHONE,
  checkCircle,
  logoutIcon
} from '../widget-success-login/widget-success-login.component';
import {
  getHTMLElementById,
  hasIsPhoneChanges,
  hasTextConfigChanges,
  hasUserChanges,
  setTextInElement
} from '@app/core/utils';
import { Subscription } from 'rxjs';
import { WidgetLocalizationService } from '@app/core/services/widget.localization.service';

@Component({
  selector: 'app-widget-success-restore',
  templateUrl: './widget-success-restore.component.html',
  styleUrls: ['./widget-success-restore.component.scss']
})
export class WidgetSuccessRestoreComponent
implements OnChanges, OnDestroy, AfterViewInit {
  /**
   * Эмиттер события логаута
   *
   * @type {EventEmitter}
   */
  @Output() logoutEvent = new EventEmitter();
  /**
   * Данные пользователя
   *
   * @type {any}
   */
  @Input() user: any;
  /**
   * Признак телефона
   *
   * @type {boolean}
   */
  @Input() isPhone: boolean;
  /**
   * Конфигурация Текстовок
   *
   * @type {any[]}
   */
  @Input() textConfig: any[];
  /**
   * Подписка на изменения языка
   *
   * @type {Subscription}
   */
  subscription: Subscription;
  /**
   * Иконка
   *
   * @type {string}
   */
  checkCircle = checkCircle;
  /**
   * Иконка разлогина
   *
   * @type {string}
   */
  logoutIcon = logoutIcon;
  /**
   * Сообщение об отправке кода на email
   *
   * @type {string}
   */
  descriptionEmail = DESCRIPTION_EMAIL;
  /**
   * Сообщение об отправке кода на телефон
   *
   * @type {string}
   */
  descriptionPhone = DESCRIPTION_PHONE;

  constructor(
    private widgetService: WidgetService,
    private localizationService: WidgetLocalizationService
  ) {
    this.subscription = this.localizationService
      .getLocalizationObservable()
      .subscribe(() => {
        this.setTexts();
      });
  }
  /**
   * Хук дестроя
   */
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  /**
   * Хук после проверки View
   */
  ngAfterViewInit() {
    this.localizationService.updateTexts();
  }
  /**
   * Хук изменения компонента
   *
   * @param changes изменения
   */
  ngOnChanges(changes: SimpleChanges) {
    if (hasUserChanges(changes) || hasIsPhoneChanges(changes)) {
      const usernameEl = getHTMLElementById('widget-restored-username');
      if (usernameEl) {
        usernameEl.innerHTML = `${
          changes.user?.currentValue && changes.user?.currentValue.passport
            ? changes.user?.currentValue.passport.name + ','
            : ''
        }`;
      }
      const restoredData = getHTMLElementById('widget-restored-data');
      if (restoredData) {
        restoredData.innerHTML = this.getEmailOrPhone(changes);
      }
      const description = getHTMLElementById('widget-restored-description');
      if (description) {
        description.innerHTML = `${this.getDescriptionText(
          changes.isPhone?.currentValue
        )}`;
      }
    }
    if (hasTextConfigChanges(changes)) {
      this.setTexts();
    }
  }

  /**
   * Получение email или телефона в зависимости от способа логина
   *
   * @param changes изменения
   * @returns email или телефон
   */
  getEmailOrPhone(changes: SimpleChanges) {
    if (changes.isPhone?.currentValue) {
      return changes.user?.currentValue && changes.user?.currentValue.contact
        ? changes.user?.currentValue.contact.phoneNumbers[0]
        : '';
    }
    return changes.user?.currentValue && changes.user?.currentValue.contact
      ? changes.user?.currentValue.contact?.emails[0]
      : '';
  }

  /**
   * Получение описания из конфига, если он есть или дефолтные значения
   *
   * @param isPhoneNumber признак телефона
   * @returns строку с описанием
   */
  getDescriptionText(isPhoneNumber: boolean) {
    const currentLanguage = this.localizationService.getCurrentLanguage();
    if (this.textConfig) {
      return this.localizationService.findByKey(
        isPhoneNumber
          ? 'widget-login-message-phone'
          : 'widget-login-message-email',
        currentLanguage.localeId
      );
    }
    return isPhoneNumber ? this.descriptionPhone : this.descriptionEmail;
  }

  /**
   * Простановка текстов из конфига
   */
  setTexts() {
    const currentLanguage = this.localizationService.getCurrentLanguage();
    if (!currentLanguage) {
      return;
    }

    const map = new Map([
      ['widget-success-restore-info', 'widget-login-link'],
      ['widget-restore-success-login', 'widget-success-login']
    ]);
    map.forEach((field, key) => {
      setTextInElement(
        key,
        this.localizationService.findByKey(field, currentLanguage.localeId)
      );
    });
    if (this.isPhone !== undefined) {
      setTextInElement(
        'widget-restored-description',
        this.localizationService.findByKey(
          this.isPhone
            ? 'widget-login-message-phone'
            : 'widget-login-message-email',
          currentLanguage.localeId
        )
      );
    }
  }

  /**
   * Обработчик нажатия на кнопку логаута
   */
  logout() {
    this.logoutEvent.emit();
  }
}
