import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanDeactivate } from '@angular/router';

import { OperatorChecksInfoComponent } from './operator-checks-info.component';

@Injectable({ providedIn: 'root' })
export class CanDeactivateGuard
implements CanDeactivate<OperatorChecksInfoComponent> {
  /**
   * Проверка на возможность уйти со страницы чека
   *
   * @param component компонент страницы чека
   */
  canDeactivate(
    component: OperatorChecksInfoComponent
  ): Observable<boolean> | boolean {
    if (component.f.comment.dirty) {
      component.openConfirmExitModal();
      return false;
    }

    return true;
  }
}
