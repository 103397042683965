import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  UntypedFormArray,
  UntypedFormControl,
  ValidatorFn,
  Validators
} from '@angular/forms';

import { checkCircle } from '../widget-success-login/widget-success-login.component';
import { first, takeUntil, switchMap, filter } from 'rxjs/operators';
import { isPhone } from 'src/app/config/validators/emailPhoneValidator';
import { States } from '../widget/widget.component';
import { WidgetService } from '@app/core/services/widget.service';
import { accountSettingsForm } from 'src/app/config/validators';
import { isNil } from 'lodash';
import { eyeCrossIcon, eyeIcon } from '../widget-login/widget-login.component';
import {
  getHTMLElementById,
  hasIsPhoneChanges,
  hasPhoneOrEmailChanges,
  hasTextConfigChanges,
  setPlaceholder,
  setTextInElement
} from '@app/core/utils';
import { Subscription, merge, of } from 'rxjs';
import { WidgetLocalizationService } from '@app/core/services/widget.localization.service';
import { WIDGET_REGISTRATION_DATA } from '../widget-registration/widget-registration.component';
import { AbstractCounterComponent } from '../../AbstractCounterComponent';
import { passwordStrengthValidator } from 'src/app/utility/passwordStrengthValidator';
import { isPlatformBrowser } from '@angular/common';
export const COUNT_FIELD_CONFIRM_BY_PHONE = 4;

const DESCRIPTION_PHONE =
  'Код подтверждения пароля отправлен Вам на номер телефона ';
const DESCRIPTION_EMAIL = 'Код подтверждения пароля отправлен Вам на Email';
@Component({
  selector: 'app-widget-verify-registration',
  templateUrl: './widget-verify-registration.component.html',
  styleUrls: ['./widget-verify-registration.component.scss']
})
export class WidgetVerifyRegistrationComponent extends AbstractCounterComponent
  implements OnChanges, OnDestroy, AfterViewInit {
  /**
   * Признак телефона
   */
  @Input() isPhone: boolean;
  /**
   * Данные восстановления
   */
  @Input() phoneOrEmail: string;
  /**
   * Конфигурация Текстовок
   */
  @Input() textConfig: any[];
  /**
   * Эмиттер события перехода при успешном сбросе пароля
   */
  @Output() registrationSuccess = new EventEmitter();
  /**
   * Подписка на изменения языка
   */
  subscription: Subscription;
  /**
   * Иконка
   */
  checkCircle = checkCircle;
  /**
   * Сообщение об отправке кода на email
   */
  descriptionEmail = DESCRIPTION_EMAIL;
  /**
   * Сообщение об отправке кода на телефон
   */
  descriptionPhone = DESCRIPTION_PHONE;
  /**
   * Массив полей ввода проверочного кода
   *
   * @type {FormArray}
   */
  codeFormArray: UntypedFormArray;
  /**
   * Признак отображения пароля
   *
   * @type {boolean}
   */
  showPassword = false;
  /**
   * Признак отображения подтверждения пароля
   *
   * @type {boolean}
   */
  showConfirmPassword = false;
  /**
   * Иконка скрытого пароля
   */
  eyeCrossIcon = eyeCrossIcon;
  /**
   * Иконка открытого пароля
   */
  eyeIcon = eyeIcon;
  /**
   * Максимальная длина пароля
   */
  passwordMaxLength = accountSettingsForm.password.maxLength;
  /**
   * Минимальная длина пароля
   */
  passwordMinLength = accountSettingsForm.password.minLength;
  /**
   * Валидатор степени безопасности пароля
   *
   * @type {function}
   */
  passwordStrength = passwordStrengthValidator;
  /**
   * Текущий язык
   */
  currentLanguage: any;

  constructor(
    private formBuilder: FormBuilder,
    private localizationService: WidgetLocalizationService,
    protected widgetService: WidgetService,
    @Inject(PLATFORM_ID) protected platform: any
  ) {
    super(widgetService);
    this.initForm();
  }
  /**
   * Получение массива контроллов
   */
  get formCodeControls() {
    return this.form.controls.code as UntypedFormArray;
  }

  /**
   * Хук инициализации
   */
  initForm(): void {
    this.form = this.formBuilder.group({
      code: this.formBuilder.array([]),
      password: [
        null,
        [
          Validators.required,
          Validators.minLength(this.passwordMinLength),
          Validators.maxLength(this.passwordMaxLength),
          this.disableCyrillicInput()
        ]
      ],
      confirm: [
        null,
        [
          Validators.required,
          Validators.minLength(this.passwordMinLength),
          Validators.maxLength(this.passwordMaxLength),
          this.disableCyrillicInput()
        ]
      ]
    });
    this.codeFormArray = this.form.controls.code as UntypedFormArray;

    for (let i = 1; i <= this.getCountCodeField(); i++) {
      this.codeFormArray.push(
        new UntypedFormControl(null, this.getCodeValidators())
      );
    }

    merge(this.f.password.valueChanges, this.f.confirm.valueChanges)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.onChangeConfirmField();
      });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.checkSubmit();
    });
    if (isPlatformBrowser(this.platform)) {
      this.countdown();
    }
    this.subscription = this.localizationService
      .getLocalizationObservable()
      .subscribe(val => {
        this.currentLanguage = val;
        this.setTexts();
      });
  }
  /**
   * Хук дестроя
   */
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  /**
   * Хук после проверки View
   */
  ngAfterViewInit() {
    this.localizationService.updateTexts();
  }
  /**
   * Хук изменения компонента
   *
   * @param changes изменения
   */
  ngOnChanges(changes: SimpleChanges) {
    if (hasIsPhoneChanges(changes) || hasPhoneOrEmailChanges(changes)) {
      const description = getHTMLElementById('reg-restore-title');
      if (!description) {
        return;
      }
      description.innerHTML = `${this.getDescriptionText(changes)}
        <span id="registration-data" class="widget-description-bold">
         ${changes.phoneOrEmail?.currentValue}</span>`;
    }
    if (hasTextConfigChanges(changes)) {
      this.setTexts();
    }
  }

  /**
   * Получение текста описания
   *
   * @param changes изменения
   * @returns текст описания
   */
  getDescriptionText(changes: SimpleChanges): string {
    const currentLanguage = this.localizationService.getCurrentLanguage();
    return this.localizationService.findByKey(
      changes.isPhone?.currentValue
        ? 'widget-restore-info-phone'
        : 'widget-restore-info-email',
      currentLanguage.localeId
    );
  }

  /**
   * Простановка текстов из конфига
   */
  setTexts() {
    const currentLanguage = this.localizationService.getCurrentLanguage();
    if (!currentLanguage) {
      return;
    }
    setTextInElement(
      'widget-verify-info',
      `${this.localizationService.findByKey(
        'widget-login-link',
        currentLanguage.localeId
      )} `
    );
    setTextInElement(
      'reg-restore-title',
      `${this.localizationService.findByKey(
        this.isPhone
          ? 'widget-restore-info-phone'
          : 'widget-restore-info-email',
        currentLanguage.localeId
      )}
      <span id="registration-data" class="widget-description-bold">
        ${this.phoneOrEmail}</span>`
    );
    const mapPlaceholders = new Map([
      ['confirm-reg', 'widget-enter-confirm-passwors-placeholder'],
      ['password-reg', 'widget-enter-password-placeholder']
    ]);
    mapPlaceholders.forEach((field, key) => {
      setPlaceholder(
        key,
        this.localizationService.findByKey(field, currentLanguage.localeId)
      );
    });
  }
  /**
   * Валидатор запрета ввода кириллицы
   *
   * @description Возвращает ошибку для поля формы, если введен символ кириллицы
   * @returns Ошибку для поля формы, если введен символ кириллицы
   */
  disableCyrillicInput(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbiddenSymbolsRegexp = /[\u0000-\u0020\u007F-\uFFFF]/;
      if (forbiddenSymbolsRegexp.test(control.value)) {
        return { forbiddenSymbols: true };
      }
      return;
    };
  }

  /**
   * Удаление пробельных символов при вводе в инпут
   *
   * @param e событие ввода
   */
  removeWhitespaces(e: InputEvent): void {
    const input = e.target as HTMLInputElement;
    input.value = input.value.replace(/\s/g, '');
  }

  /**
   * Получение введённого кода подтверждения
   *
   * @description Из инпутов ввода кода подтверждения получает введённые значения и формирует из них
   * строку.
   * @returns строку с введённым кодом подтверждения
   */
  collectCode(): string {
    return this.f.code.value.join('');
  }

  /**
   * Смена признака отображения пароля
   */
  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  /**
   * Смена признака отображения подтверждения пароля
   */
  toggleShowConfirmPassword(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
  /**
   * Проверка валидности формы и сабмит формы
   */
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    const shopId = localStorage.getItem('WIDGET_SHOP_ID');
    const registration = this.getRegistrationInfo();
    const isPhoneFlag = isPhone(registration);
    const code = this.collectCode();
    const password = this.f.password.value;
    this.widgetService
      .confirmExpressRegisterBySmsCode(code, registration, password, shopId)
      .pipe(first(), takeUntil(this.destroy$))
      .subscribe(
        () => {
          localStorage.removeItem(WIDGET_REGISTRATION_DATA);
          this.authorize(registration, password, isPhoneFlag);
        },
        ({ error }: any) => {
          this.loading = false;
          this.handleError(error);
        }
      );
  }
  /**
   * Обработчик события вставки в поле ввода
   *
   * @param event переменная, содержащая информацию о буфере обмена
   * @param fieldName имя поля
   */
  onPaste(event: ClipboardEvent, fieldName: string): void {
    event.preventDefault();
    const val = event.clipboardData.getData('text/plain').replace(/\s/g, '');
    this.f[fieldName].setValue(val);
    setTimeout(() => this.f[fieldName].updateValueAndValidity(), 0);
    this.onChangeConfirmField();
  }
  /**
   * Авторизация при успешной регистрации
   *
   * @param username - логин
   * @param passpord - пароль
   * @param isPhoneFlag - признак телефона
   */
  authorize(username: string, passpord: string, isPhoneFlag: boolean) {
    this.loading = true;
    this.clearInterval();
    this.widgetService
      .authorize(username, passpord)
      .pipe(
        switchMap(res => {
          if (!res || !res['access_token']) {
            this.loading = false;
            return of(null);
          }
          return this.widgetService.saveToken(res);
        }),
        filter(v => v !== null),
        switchMap(() => this.widgetService.obtainRegAuthUser())
      )
      .subscribe(
        user => {
          this.loading = false;
          if (user) {
            this.registrationSuccess.emit({
              isPhone: isPhoneFlag,
              user: {
                passport: {
                  name: user.name,
                  surname: user.surname
                },
                contact: {
                  emails: isPhoneFlag ? [] : [user.login],
                  phoneNumbers: isPhoneFlag ? [username] : []
                }
              },
              newState: States.SUCCESS_REGISTRATION
            });
          }
        },
        err => {
          this.loading = false;
          if (err?.error?.error && err.error.error === 'invalid_grant') {
            this.form.setErrors({ invalid_creds: true });
          }
        }
      );
  }
  /**
   * Обработчик ошибок
   *
   * @param error Объект ошибки
   */
  handleError(error: any): void {
    if (!error) {
      this.loading = false;
      return;
    }
    if (!error.errors) {
      return;
    }
    for (const er of error.errors) {
      const { field, code: errorCode, data } = er;
      this.processCodeOfErrors(errorCode, data, field);
    }
    this.loading = false;
  }

  /**
   * Анализ кода ошибки и отображение по найденному коду
   *
   * @param errorCode код ошибки
   * @param data данные
   * @param field наименование поля
   */
  processCodeOfErrors(errorCode: string, data: any, field: string): void {
    const errorField = errorCode;
    this.f[field].setErrors({ [errorField]: true });
    this.enableShowFieldErrors(field);
  }
  /**
   * Получение информации о логине
   *
   * @returns логин
   */
  getRegistrationInfo() {
    const phoneOrEmailElement = getHTMLElementById('registration-data');
    if (!phoneOrEmailElement) {
      return '';
    }
    return phoneOrEmailElement.innerHTML.replace(/\s/g, '');
  }
  /**
   * Повторная отправка кода
   */
  sendAgain() {
    this.loadingResend = true;
    const regInfo = this.getRegistrationInfo();
    this.widgetService
      .resendSms(regInfo, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.restartCounter();
        },
        () => {
          this.restartCounter();
        }
      );
  }

  /**
   * Получение локализованной ошибки по длине пароля
   *
   * @param fieldName имя поля
   * @param isMax признак ограничения максимальной длины
   * @returns локализованная ошибка
   */
  getPasswordErrorText(fieldName: string, isMax: boolean) {
    const textNode = this.localizationService.findByKey(
      fieldName,
      this.currentLanguage.localeId
    );
    if (isMax) {
      return textNode.replace(
        '{{ passwordMaxLength }}',
        this.passwordMaxLength.toString()
      );
    }
    return textNode.replace(
      '{{ passwordMinLength }}',
      this.passwordMinLength.toString()
    );
  }

  /**
   * Получение локализованной ошибки
   *
   * @param fieldName имя поля
   * @returns локализованная ошибка
   */
  getErrorText(fieldName: string) {
    return this.localizationService.findByKey(
      fieldName,
      this.currentLanguage.localeId
    );
  }

  /**
   * Сравнить пароль и подтверждение пароля, выставить ошибку
   */
  onChangeConfirmField() {
    const password = this.f.password;
    const confirm = this.f.confirm;
    if (!password || !confirm) {
      return;
    }

    let errors = { ...confirm.errors };
    delete errors.passwordDiff;
    if (
      !isNil(password.value) &&
      !isNil(confirm.value) &&
      password.value !== confirm.value
    ) {
      errors = { ...errors, passwordDiff: true };
    }
    const hasErrors = Object.keys(errors).length !== 0;
    this.f['confirm'].setErrors(hasErrors ? errors : null);
    this.checkSubmit();
  }

  /**
   * Получение длины кода подтверждения
   *
   * @description Получает константу длины кода подтверждения.
   * @returns длину кода подтверждения
   */
  private getCountCodeField(): number {
    return COUNT_FIELD_CONFIRM_BY_PHONE;
  }
  /**
   * Получение валидаторов ввода для кода подтверждения
   *
   * @description Формирует массив валидаторов для поля ввода одного символа кода подтверждения.
   * @returns валидаторы для ввода кода подтверждения
   */
  private getCodeValidators(): ValidatorFn[] {
    return [Validators.required, Validators.maxLength(1)];
  }
}
