/* eslint-disable id-denylist */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { assign } from 'lodash';

import { environment } from 'src/environments/environment';
import { api } from 'src/app/config/api';
import { AuthService } from './auth.service';
import {
  TradingNetwork,
  TradingNetworksSort,
  TradingNetworksFilter, TradingNetworkFilterValue
} from '../models';
import { TradingNetworkOrg } from '../models/TradingNetworkOrg';
import { sorting } from 'src/app/utility';
import { PaginableClass } from '../models/PaginableClass';
import { map } from 'rxjs/operators';
import { TradingInternetShop } from '../models/TradingInternetShop';
import { AnalyticFilter } from '../models/AnalyticFilter';
import { PreorderAnalitycs } from '../models/PreordersAnalytic';
import { AnalyticStats } from '../models/AnalyticStats';

export const preorderMock: PaginableClass<PreorderAnalitycs> = {
  page: 1,
  pageSize: 10,
  totalElements: 11,
  unfilteredCount: 11,
  elements: [
    {
      shopId: 'shopId',
      customer: {
        age: 30,
        birthday: '28.10.1992',
        city: 'Novi Sad',
        country: 'Serbia',
        email: 'danyaivayev@gmail.com',
        gender: 'MALE',
        name: 'Danis',
        phone: '+381 638087724',
        registerType: 'PORTAL',
        surname: 'Ivaev'
      },
      login: 'login',
      order: {
        id: '32143242',
        commodities: [],
        number: '1224244',
        orderDate: '2023-04-20T13:11:36Z',
        preorderDate: '2023-04-20T13:11:36Z',
        orderStatus: 'PAID',
        orderType: 'ORDER',
        quantity: 13,
        shopName: 'TSUM',
        sum: 12000
      }
    },
    {
      shopId: 'shopId',
      customer: {
        age: 28,
        birthday: '20.05.1995',
        city: 'Novi Sad',
        country: 'Serbia',
        email: 'alina@gmail.com',
        gender: 'FEMALE',
        name: 'ALINA',
        phone: '+381 638087724',
        registerType: 'WIDGET',
        surname: 'Muraeva'
      },
      login: 'login',
      order: {
        id: '32143242',
        commodities: [],
        number: '122244535',
        orderDate: '2023-04-20T13:11:36Z',
        preorderDate: '2023-04-20T13:11:36Z',
        orderStatus: null,
        orderType: 'PRE_ORDER',
        quantity: 13,
        shopName: 'TSUM',
        sum: 10656
      }
    },
    {
      shopId: 'shopId',
      customer: {
        age: 28,
        birthday: '20.05.1995',
        city: 'Novi Sad',
        country: 'Serbia',
        email: 'alina@gmail.com',
        gender: 'FEMALE',
        name: 'ALINA',
        phone: '+381 638087724',
        registerType: 'WIDGET',
        surname: 'Muraeva'
      },
      login: 'login',
      order: {
        id: '32143242',
        commodities: [],
        number: '122244535',
        orderDate: '2023-04-20T13:11:36Z',
        preorderDate: '2023-04-20T13:11:36Z',
        orderStatus: null,
        orderType: 'PRE_ORDER',
        quantity: 13,
        shopName: 'TSUM',
        sum: 10656
      }
    },
    {
      shopId: 'shopId',
      customer: {
        age: 28,
        birthday: '20.05.1995',
        city: 'Novi Sad',
        country: 'Serbia',
        email: 'alina@gmail.com',
        gender: 'FEMALE',
        name: 'ALINA',
        phone: '+381 638087724',
        registerType: 'WIDGET',
        surname: 'Muraeva'
      },
      login: 'login',
      order: {
        id: '32143242',
        commodities: [],
        number: '122244535',
        orderDate: '2023-04-20T13:11:36Z',
        preorderDate: '2023-04-20T13:11:36Z',
        orderStatus: null,
        orderType: 'PRE_ORDER',
        quantity: 13,
        shopName: 'TSUM',
        sum: 10656
      }
    },
    {
      shopId: 'shopId',
      customer: {
        age: 28,
        birthday: '20.05.1995',
        city: 'Novi Sad',
        country: 'Serbia',
        email: 'alina@gmail.com',
        gender: 'FEMALE',
        name: 'ALINA',
        phone: '+381 638087724',
        registerType: 'WIDGET',
        surname: 'Muraeva'
      },
      login: 'login',
      order: {
        id: '32143242',
        commodities: [],
        number: '122244535',
        orderDate: '2023-04-20T13:11:36Z',
        preorderDate: '2023-04-20T13:11:36Z',
        orderStatus: null,
        orderType: 'PRE_ORDER',
        quantity: 13,
        shopName: 'TSUM',
        sum: 10656
      }
    },
    {
      shopId: 'shopId',
      customer: {
        age: 28,
        birthday: '20.05.1995',
        city: 'Novi Sad',
        country: 'Serbia',
        email: 'alina@gmail.com',
        gender: 'FEMALE',
        name: 'ALINA',
        phone: '+381 638087724',
        registerType: 'WIDGET',
        surname: 'Muraeva'
      },
      login: 'login',
      order: {
        id: '32143242',
        commodities: [],
        number: '122244535',
        orderDate: '2023-04-20T13:11:36Z',
        preorderDate: '2023-04-20T13:11:36Z',
        orderStatus: null,
        orderType: 'PRE_ORDER',
        quantity: 13,
        shopName: 'TSUM',
        sum: 10656
      }
    },
    {
      shopId: 'shopId',
      customer: {
        age: 28,
        birthday: '20.05.1995',
        city: 'Novi Sad',
        country: 'Serbia',
        email: 'alina@gmail.com',
        gender: 'FEMALE',
        name: 'ALINA',
        phone: '+381 638087724',
        registerType: 'WIDGET',
        surname: 'Muraeva'
      },
      login: 'login',
      order: {
        id: '32143242',
        commodities: [],
        number: '122244535',
        orderDate: '2023-04-20T13:11:36Z',
        preorderDate: '2023-04-20T13:11:36Z',
        orderStatus: null,
        orderType: 'PRE_ORDER',
        quantity: 13,
        shopName: 'TSUM',
        sum: 10656
      }
    },
    {
      shopId: 'shopId',
      customer: {
        age: 28,
        birthday: '20.05.1995',
        city: 'Novi Sad',
        country: 'Serbia',
        email: 'alina@gmail.com',
        gender: 'FEMALE',
        name: 'ALINA',
        phone: '+381 638087724',
        registerType: 'WIDGET',
        surname: 'Muraeva'
      },
      login: 'login',
      order: {
        id: '32143242',
        commodities: [],
        number: '122244535',
        orderDate: '2023-04-20T13:11:36Z',
        preorderDate: '2023-04-20T13:11:36Z',
        orderStatus: null,
        orderType: 'PRE_ORDER',
        quantity: 13,
        shopName: 'TSUM',
        sum: 10656
      }
    },
    {
      shopId: 'shopId',
      customer: {
        age: 28,
        birthday: '20.05.1995',
        city: 'Novi Sad',
        country: 'Serbia',
        email: 'alina@gmail.com',
        gender: 'FEMALE',
        name: 'ALINA',
        phone: '+381 638087724',
        registerType: 'WIDGET',
        surname: 'Muraeva'
      },
      login: 'login',
      order: {
        id: '32143242',
        commodities: [],
        number: '122244535',
        orderDate: '2023-04-20T13:11:36Z',
        preorderDate: '2023-04-20T13:11:36Z',
        orderStatus: null,
        orderType: 'PRE_ORDER',
        quantity: 13,
        shopName: 'TSUM',
        sum: 10656
      }
    },
    {
      shopId: 'shopId',
      customer: {
        age: 28,
        birthday: '20.05.1995',
        city: 'Novi Sad',
        country: 'Serbia',
        email: 'alina@gmail.com',
        gender: 'FEMALE',
        name: 'ALINA',
        phone: '+381 638087724',
        registerType: 'WIDGET',
        surname: 'Muraeva'
      },
      login: 'login',
      order: {
        id: '32143242',
        commodities: [],
        number: '122244535',
        orderDate: '2023-04-20T13:11:36Z',
        preorderDate: '2023-04-20T13:11:36Z',
        orderStatus: null,
        orderType: 'PRE_ORDER',
        quantity: 13,
        shopName: 'TSUM',
        sum: 10656
      }
    }
  ]
};
/**
 * Сервис для работы с Торговыми сетями и магазинами
 */
@Injectable({ providedIn: 'root' })
export class TradingNetworkService {
  /**
   * Http Опции
   */
  httpOptions = {};

  constructor(private http: HttpClient, private authService: AuthService) {
    this.httpOptions = this.makeHttpOptionsByAuthState(
      this.authService.isLoggedIn()
    );
  }


  /**
   * Получение списка торговых сетей
   *
   * @param sort сортировка
   * @param filter фильтр
   * @returns Наблюдателя за получением списка торговых сетей
   */
  getTradingNetworksWithDigitalSignature(
    sort?: TradingNetworksSort,
    filter?: TradingNetworksFilter
  ): Observable<PaginableClass<TradingNetwork>> {
    const params = assign(
      sort ? sort.toHttpParams() : {},
      filter ? filter.toHttpParams() : {}
    );
    const headers = {};
    const httpOpt = Object.assign(headers, this.httpOptions);
    const options = Object.assign(httpOpt, { params });
    return this.http
      .get<PaginableClass<TradingNetwork>>(
        this.getTradingNetworksWithDigitalSignaturePath(),
        options)
      .pipe(
        map(res => {
          res.elements = sort ? res.elements : sorting(res.elements, 'name');
          return res;
        })
      );
  }

  /**
   * Получение списка торговых сетей
   *
   * @param sort сортировка
   * @param filter фильтр
   * @returns Наблюдателя за получением списка торговых сетей
   */
  getTradingNetworks(
    sort?: TradingNetworksSort,
    filter?: TradingNetworksFilter
  ): Observable<PaginableClass<TradingNetwork>> {
    const params = assign(
      sort ? sort.toHttpParams() : {},
      filter ? filter.toHttpParams() : {}
    );
    const headers = {};
    const httpOpt = Object.assign(headers, this.httpOptions);
    const options = Object.assign(httpOpt, { params });
    return this.http
      .get<PaginableClass<TradingNetwork>>(
        this.getTradingNetworksPath(),
        options
    )
      .pipe(
        map(res => {
          res.elements = sort ? res.elements : sorting(res.elements, 'name');
          return res;
        })
      );
  }

  /**
   * Получение списка торговых сетей для фильтра на странице спец. предложений
   *
   * @returns Наблюдателя за получением списка торговых сетей для фильтра
   * на странице спец. предложений
   */
  getTradingNetworksForSpecialOffersFilter(): Observable<TradingNetworkFilterValue[]> {
    return this.http.get<any>(
      this.getTradingNetworksForSpecialOffersFilterPath(),
      this.httpOptions
    );
  }

  /**
   * Получение торговой сети по ID
   *
   * @param tradingNetworkId id торговой сети
   * @returns Наблюдателя за Получение торговой сети по ID
   */
  getTradingNetworkById(tradingNetworkId: string): Observable<TradingNetwork> {
    return this.http.get<TradingNetwork>(
      this.getTradingNetworkByIdPath(tradingNetworkId),
      this.httpOptions
    );
  }

  /**
   * Получение ИМ по ID торговой сети
   *
   * @param tradingNetworkId id торговой сети
   * @param sort сортировка
   * @returns Наблюдателя за Получение торговой сети по ID
   */
  getTradingInternetShopById(
    tradingNetworkId: string,
    sort?: any
  ): Observable<TradingInternetShop[]> {
    const params = assign(sort ? sort.toHttpParams() : {});
    const headers = {};
    const httpOpt = Object.assign(headers, this.httpOptions);
    const options = Object.assign(httpOpt, { params });
    return this.http.get<any>(
      this.getTradingAddShopPath(tradingNetworkId),
      options
    );
  }

  /**
   * Удаление магазина по shopId
   *
   * @param shopId Id магазина
   * @param tradingNetworkId Id ОРТ
   * @returns Наблюдателя за запросом удаления
   */
  removeShop(shopId: string, tradingNetworkId: string) {
    return this.http.delete(
      `${this.getTradingAddShopPath(tradingNetworkId)}/${shopId}`,
      this.httpOptions
    );
  }
  /**
   * Сохранение данных виджета
   *
   * @param data данные виджета
   * @returns Наблюдателя за выполнением запроса
   */
  saveWidgetData(data: any) {
    return this.http.post(this.getWidgetCreatePath(), data, this.httpOptions);
  }

  /**
   * Получение конфигурации виджета по id
   *
   * @param widgetId id виджета
   * @returns Наблюдателя за получением виджета
   */
  getWidgetById(widgetId: string): Observable<any> {
    return this.http.get(
      `${this.getWidgetCreatePath()}/${widgetId}`,
      this.httpOptions
    );
  }
  /**
   * Получение конфигурации виджета по id
   *
   * @param widgetId id виджета
   * @param data данные
   * @returns Наблюдателя за получением виджета
   */
  updateWidgetById(widgetId: string, data: any): Observable<any> {
    return this.http.put(
      `${this.getWidgetCreatePath()}/${widgetId}`,
      data,
      this.httpOptions
    );
  }
  /**
   * Добавление торговой сети
   *
   * @param tradingNetwork данные торговой сети
   * @returns Наблюдателя за добавлением торговой сети
   */
  addTradingNetworkWithStores(tradingNetwork: TradingNetwork): Observable<any> {
    return this.http.post<any>(
      this.getTradingNetworksWithStoresPath(),
      tradingNetwork,
      this.httpOptions
    );
  }

  /**
   * Обновление торговой сети
   *
   * @param tradingNetwork данные торговой сети
   * @returns Наблюдателя за обновлением торговой сети
   */
  updateTradingNetwork(tradingNetwork: TradingNetwork): Observable<any> {
    return this.http.put<any>(
      this.getTradingNetworkByIdPath(tradingNetwork.id),
      tradingNetwork,
      this.httpOptions
    );
  }

  /**
   * Удаление торговой сети
   *
   * @param tradingNetworkId id торговой сети
   * @returns Наблюдателя за удалением торговой сети
   */
  removeTradingNetwork(tradingNetworkId: string): Observable<any> {
    return this.http.delete<any>(
      this.getTradingNetworkByIdPath(tradingNetworkId),
      this.httpOptions
    );
  }

  /**
   * Получение списка магазинов
   *
   * @param tradingNetworkId id торговой сети
   * @param sortOrder сортировка
   * @returns Наблюдателя за получением списка магазинов
   */
  getTradingNetworkOrganizations(
    tradingNetworkId: string,
    sortOrder?
  ): Observable<TradingNetworkOrg[]> {
    if (sortOrder) {
      this.httpOptions['params'] = { sortOrder };
    }
    return this.http.get<TradingNetworkOrg[]>(
      this.getTradingNetworkOrgPath(tradingNetworkId),
      this.httpOptions
    );
  }

  /**
   * Создание магазина
   *
   * @param tradingNetworkId id торговой сети
   * @param tradingNetworkOrg данные магазина
   * @returns Наблюдателя за созданием магазина
   */
  addTradingNetworkOrg(
    tradingNetworkId: string,
    tradingNetworkOrg: any
  ): Observable<any> {
    return this.http.post<any>(
      this.getTradingNetworkOrgPath(tradingNetworkId),
      tradingNetworkOrg,
      this.httpOptions
    );
  }

  /**
   * Обновление магазина
   *
   * @param tradingNetworkId id торговой сети
   * @param tradingNetworkOrg данные магазина
   * @returns Наблюдателя за обновлением магазина
   */
  updateTradingNetworkOrg(
    tradingNetworkId: string,
    tradingNetworkOrg: TradingNetworkOrg
  ): Observable<any> {
    return this.http.put<any>(
      this.getTradingNetworkOrgByIdPath(tradingNetworkId, tradingNetworkOrg.id),
      tradingNetworkOrg,
      this.httpOptions
    );
  }

  /**
   * Удаление магазина
   *
   * @param tradingNetworkOrgId id магазина
   * @returns Наблюдателя за удалением магазина
   */
  removeTradingNetworkOrgbyId(tradingNetworkOrgId: string): Observable<any> {
    return this.http.delete<any>(
      this.getTradingNetworkOrgsPath(tradingNetworkOrgId),
      this.httpOptions
    );
  }

  /**
   * Удаление торговой сети
   *
   * @param tradingNetworkId id торговой сети
   * @returns Наблюдателя за удалением торговой сети
   */
  removeTradingNetworkOrganizations(tradingNetworkId: string): Observable<any> {
    return this.http.delete<any>(
      this.getTradingNetworkOrgPath(tradingNetworkId),
      this.httpOptions
    );
  }

  /**
   * Получение списка ОРТ
   *
   * @returns Наблюдателя за Получение списка ОРТ
   */
  obtainOrganizations(): Observable<any> {
    return this.http.get(this.getOrganizationsPath(), this.httpOptions);
  }

  /**
   * Получение списка торговых сетей для фильтра ОРТ
   *
   * @returns Наблюдателя за получением списка торговых сетей для фильтра ОРТ
   */
  getTradingNetworksWithCheques(): Observable<any> {
    return this.http.get(
      this.getTradingNetworksWithChequesPath(),
      this.httpOptions
    );
  }

  /**
   * Создание ИМ у ОРТ
   *
   * @param data данные ИМ
   * @param tradingNetworkId id ОРТ
   */
  createShopForTrading(data: any, tradingNetworkId: string) {
    return this.http.post(
      this.getTradingAddShopPath(tradingNetworkId),
      data,
      this.httpOptions
    );
  }

  /**
   * Обновление ИМ у ОРТ
   *
   * @param shopID id магазина
   * @param data данные ИМ
   * @param tradingNetworkId id ОРТ
   */
  updateShopForTrading(shopID: string, data: any, tradingNetworkId: string) {
    return this.http.put(
      `${this.getTradingAddShopPath(tradingNetworkId)}/${shopID}`,
      data,
      this.httpOptions
    );
  }

  /**
   * Получение лимитов для фильтра
   *
   * @returns Наблюдателя за получением лимитов
   */
  getAnalyticFiltersLimits(): Observable<AnalyticStats> {
    return this.http.get<AnalyticStats>(
      this.getAnalyticStatPath(),
      this.httpOptions
    );
  }

  /**
   * Получение таблицы аналитики предзаказов
   *
   * @param filter фильтр
   * @returns Наблюдателя за получением таблицы
   */
  getAnalyticPreorders(
    filter: AnalyticFilter
  ): Observable<PaginableClass<PreorderAnalitycs>> {
    const params = assign(filter ? filter.toHttpParams() : {});
    const headers = {};
    const httpOpt = Object.assign(headers, this.httpOptions);
    const options = Object.assign(httpOpt, { params });
    return this.http.get<PaginableClass<PreorderAnalitycs>>(
      this.getAnalyticPath(),
      options
    );
  }
  /**
   * Выгрузка файла CSV аналитики предзаказов
   *
   * @param filter фильтр
   * @returns Наблюдателя за выгрузкой
   */
  downloadAnalyticPreorders(filter: AnalyticFilter): Observable<any> {
    const params = assign(filter ? filter.toHttpParams() : {});
    delete params.page;
    delete params.pageSize;
    delete params.sort;
    const headers = {};
    const httpOpt = Object.assign(headers, this.getDownloadingHttpOptions());
    const options = Object.assign(httpOpt, { params });
    return this.http.get<any>(this.getAnalyticPathCSV(), options);
  }

  /**
   * Генерация Http опций в зависимости от того, залогинен пользователь или нет
   *
   * @param isLoggedIn признак, залогинен ли пользователь
   * @returns Http опции
   */
  private makeHttpOptionsByAuthState(isLoggedIn: boolean = false) {
    if (isLoggedIn) {
      return this.authService.getSecuredHttpOptions();
    }
    return {};
  }
  /**
   * Генерация URL для получения торговых сетей
   *
   * @returns URL для получения торговых сетей
   */
  private getTradingNetworksPath(): string {
    return environment.basePortalApiUrl + api.tradingNetwork.tradeNetworks;
  }

  /**
   * Генерация URL для получения значений торговых сетей для фильтра спец предложений
   *
   * @returns UURL для получения значений торговых сетей для фильтра спец предложений
   */
  private getTradingNetworksForSpecialOffersFilterPath(): string {
    return environment.basePortalApiUrl + api.tradingNetwork.tradeNetworksForOfferFilter;
  }

  /**
   * Генерация URL для получения торговых сетей из ЛК админа
   *
   * @returns URL для получения торговых сетей из ЛК админа с данными подписи
   */
  private getTradingNetworksWithDigitalSignaturePath(): string {
    return environment.basePortalApiUrl + api.tradingNetwork.tradeNetworksWithDigitalSignature;
  }
  /**
   * Генерация URL для создания торговой сети
   *
   * @returns URL для создания торговой сети
   */
  private getTradingNetworksWithStoresPath(): string {
    return environment.basePortalApiUrl + api.tradingNetwork.addWithStores;
  }

  /**
   * Генерация URL для оперция с торговыми сетями
   *
   * @param tradingNetworkId id торговой сети
   * @returns URL для оперция с торговыми сетями
   */
  private getTradingNetworkByIdPath(tradingNetworkId: string): string {
    return (
      environment.basePortalApiUrl +
      api.tradingNetwork.tradeNetworkById.replace(
        '{tradingNetworkId}',
        tradingNetworkId
      )
    );
  }
  /**
   * Генерация URL для получения списка ОРТ
   *
   * @returns URL для получения списка ОРТ
   */
  private getOrganizationsPath(): string {
    return environment.basePortalApiUrl + api.tradingNetwork.organizations;
  }

  /**
   * Генерация URL для операций с магазинами и сетями по ID
   *
   * @param tradingNetworkId id сети или магазина
   * @returns URL для операций с магазинами и сетями по ID
   */
  private getTradingNetworkOrgPath(tradingNetworkId: string): string {
    return (
      environment.basePortalApiUrl +
      api.tradingNetwork.tradeNetworkOrg.replace(
        '{tradingNetworkId}',
        tradingNetworkId
      )
    );
  }

  /**
   * Генерация URL для обновления торговой сети
   *
   * @param tradingNetworkId id торговой сети
   * @param tradingNetworkOrgId  id магазина
   * @returns URL для обновления торговой сети
   */
  private getTradingNetworkOrgByIdPath(
    tradingNetworkId: string,
    tradingNetworkOrgId: string
  ): string {
    return (
      environment.basePortalApiUrl +
      api.tradingNetwork.tradeNetworkOrg.replace(
        '{tradingNetworkId}',
        tradingNetworkId
      ) +
      `/${tradingNetworkOrgId}`
    );
  }

  /**
   * Генерация URL для удаления магазина
   *
   * @param tradingNetworkOrgId id магазина
   * @returns URL для удаления магазина
   */
  private getTradingNetworkOrgsPath(tradingNetworkOrgId: string): string {
    return (
      environment.basePortalApiUrl +
      api.tradingNetwork.organizations +
      `/${tradingNetworkOrgId}`
    );
  }
  /**
   * Генерация URL для получения списка торговых сетей для фильтра ОРТ
   *
   * @returns URL для получения списка торговых сетей для фильтра ОРТ
   */
  private getTradingNetworksWithChequesPath(): string {
    return (
      environment.basePortalApiUrl + api.tradingNetwork.tradeNetworksWithCheques
    );
  }
  /**
   * Генерация URL для добавления ИМ
   *
   * @param tradingNetworkId id ОРТ
   * @returns URL для добавления ИМ
   */
  private getTradingAddShopPath(tradingNetworkId: string): string {
    return (
      environment.basePortalApiUrl +
      api.tradingNetwork.addShop.replace('{tradingNetworkId}', tradingNetworkId)
    );
  }
  /**
   * Генерация URL для добавления виджета в ИМ
   *
   * @param tradingNetworkId id ОРТ
   * @returns URL для добавления ИМ
   */
  private getWidgetCreatePath() {
    return environment.basePortalApiUrl + api.widget.imCreate;
  }
  /**
   * Генерация URL для получения аналитики
   *
   * @returns URL для получения аналитики
   */
  private getAnalyticPath() {
    return environment.widgetLogService + api.widget.widgetPath;
  }
  /**
   * Генерация URL для получения статистики по филтрам аналитики
   *
   * @returns URL для получения статистики по филтрам аналитики
   */
  private getAnalyticStatPath() {
    return environment.widgetLogService + api.widget.stats;
  }
  /**
   * Генерация URL для получения CSV аналитики
   *
   * @returns URL для получения аналитики
   */
  private getAnalyticPathCSV() {
    return environment.widgetLogService + api.widget.analyticCSV;
  }

  /**
   * Генерация http опций для загрузки файлов
   *
   * @returns http опции
   */
  private getDownloadingHttpOptions() {
    return {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      responseType: 'blob' as 'json'
    };
  }
}
