import { isNil } from 'lodash';
import { Serializable } from '../Serializable';

export class OfferFilter extends Serializable {
  active: boolean;
  tradeNetworkIds?: any[];

  /**
   * Возвращает параметры
   *
   */
  toHttpParams(): any {
    const result = {};

    if (!isNil(this.active)) {
      result['active'] = this.active;
    }
    if (this.tradeNetworkIds) {
      result['tradeNetworkIds'] = this.tradeNetworkIds;
    }
    return result;
  }
}
