import { Serializable } from '../Serializable';

export class TradingNetworksFilter extends Serializable {
  page = 1;
  pageSize = 10;
  name: string;

  /**
   * Возвращает параметры
   *
   */
  toHttpParams(): any {
    const result = {};

    if (this.page) {
      result['page'] = this.page;
    }
    if (this.pageSize) {
      result['pageSize'] = this.pageSize;
    }
    if (this.name) {
      result['name'] = this.name;
    }
    return result;
  }
}
