import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { assign } from 'lodash';

import { environment } from '../../../environments/environment';
import { api } from '../../config';
import { Partner } from '../models/Partner';
import { AuthService } from './auth.service';
import { WidgetFilter } from '../models/Widget/WidgetFilter';
import { PaginableClass } from '../models/PaginableClass';
import { AnalyticFilter } from '../models/AnalyticFilter';
import { PreorderAnalitycs } from '../models/PreordersAnalytic';
import { AnalyticStats } from '../models/AnalyticStats';
/**
 * Сервис для CRUD Операций с профилем партнера
 */
@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  /**
   * Http Опции
   */
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getAccessToken()
    })
  };

  constructor(private http: HttpClient, private authService: AuthService) {}

  /**
   * Создание партнера
   *
   * @param partner данные партнера
   * @returns Наблюдателя за созданием партнера
   */
  add(partner: Partner): Observable<Partner> {
    return this.http.post<Partner>(
      this.getPartnerPath(),
      partner,
      this.httpOptions
    );
  }

  /**
   * Обновление партнера
   *
   * @param partner данные партнера
   * @returns Наблюдателя за обновлением партнера
   */
  update(partner: Partner): Observable<Partner> {
    return this.http.put<Partner>(
      this.getPartnerByIdPath(partner.id),
      partner,
      this.httpOptions
    );
  }

  /**
   * Получение данных партнера по ID
   *
   * @param userId id партнера
   * @returns Наблюдателя за получением данных партнера по ID
   */
  getByUserId(userId: string): Observable<any> {
    return this.http.get<any>(
      this.getPartnerByUserIdPath(userId),
      this.httpOptions
    );
  }
  /**
   * Получение всех рабочих конфигов виджетов
   *
   * @param filter фильтр виджетов
   * @returns Наблдателя за получением конфигов виджетов
   */
  getOrganizations(filter: WidgetFilter): Observable<any> {
    const params = filter.toHttpParams();
    const headers = {};
    const httpOpt = Object.assign(headers, this.httpOptions);
    const options = Object.assign(httpOpt, { params });
    return this.http.get<any>(this.getOrganizationPath(), options);
  }
  /**
   * Получение конфигурации виджета по id
   *
   * @param widgetId id виджета
   * @returns Наблюдателя за получением виджета
   */
  getWidgetById(widgetId: string): Observable<any> {
    return this.http.get(
      `${this.getWidgetPath()}/by-im/${widgetId}`,
      this.httpOptions
    );
  }

  /**
   * Сохранение данных виджета
   *
   * @param data данные виджета
   * @returns Наблюдателя за выполнением запроса
   */
  saveWidgetData(data: any) {
    return this.http.post(this.getWidgetPath(), data, this.httpOptions);
  }

  /**
   * Сохранение данных виджета
   *
   * @param id Id магазина
   * @param data данные виджета
   * @returns Наблюдателя за выполнением запроса
   */
  updateWidgetData(id: string, data: any) {
    return this.http.put(
      `${this.getWidgetPath()}/${id}`,
      data,
      this.httpOptions
    );
  }

  /**
   * Сохранение магазина
   *
   * @param info информация для сохранения
   * @returns Наблюдателя за сохранением
   */
  saveShopInfo(info: any) {
    return this.http.post(this.getOrganizationPath(), info, this.httpOptions);
  }

  /**
   * Удаление магазина
   *
   * @param id id магазина
   * @returns Наблюдателя за магазином
   */
  removeShop(id: string) {
    return this.http.delete(
      `${this.getOrganizationPath()}/${id}`,
      this.httpOptions
    );
  }

  /**
   * Сохранение магазина
   *
   * @param id id ИМ
   * @param info информация для сохранения
   * @returns Наблюдателя за сохранением
   */
  updateShopInfo(id: string, info: any) {
    return this.http.put(
      `${this.getOrganizationPath()}/${id}`,
      info,
      this.httpOptions
    );
  }

  /**
   * Получение равки скрипта инициализации
   *
   * @returns Наблюдателя за получением равки скрипта
   */
  getRawScript() {
    return this.http.get('/assets/init-script.html', {
      responseType: 'text' as 'json'
    });
  }

  /**
   * Получение таблицы аналитики предзаказов
   *
   * @param filter фильтр
   * @returns Наблюдателя за получением таблицы
   */
  getAnalyticPreorders(
    filter: AnalyticFilter
  ): Observable<PaginableClass<PreorderAnalitycs>> {
    const params = assign(filter ? filter.toHttpParams() : {});
    const headers = {};
    const httpOpt = Object.assign(headers, this.httpOptions);
    const options = Object.assign(httpOpt, { params });
    return this.http.get<PaginableClass<PreorderAnalitycs>>(
      this.getAnalyticPreordersPath(),
      options
    );
  }
  /**
   * Выгрузка файла CSV аналитики предзаказов
   *
   * @param filter фильтр
   * @returns Наблюдателя за выгрузкой
   */
  downloadAnalyticPreorders(filter: AnalyticFilter): Observable<any> {
    const params = assign(filter ? filter.toHttpParams() : {});
    delete params.page;
    delete params.pageSize;
    delete params.sort;
    const headers = {};
    const httpOpt = Object.assign(headers, this.getDownloadingHttpOptions());
    const options = Object.assign(httpOpt, { params });
    return this.http.get<any>(this.getAnalyticPathCSV(), options);
  }
  /**
   * Получение лимитов для фильтра
   *
   * @returns Наблюдателя за получением лимитов
   */
  getAnalyticFiltersLimits(): Observable<AnalyticStats> {
    return this.http.get<AnalyticStats>(
      this.getAnalyticStatPath(),
      this.httpOptions
    );
  }
  /**
   * Генерация URL для создания партнера
   *
   * @returns URL для создания партнера
   */
  private getPartnerPath(): string {
    return environment.basePortalApiUrl + api.partner.partner;
  }

  /**
   * Генерация URL для обновления партнера
   *
   * @param partnerId id партнера
   * @returns URL для обновления партнера
   */
  private getPartnerByIdPath(partnerId: string): string {
    return (
      environment.basePortalApiUrl +
      api.partner.partnerById.replace('{id}', partnerId)
    );
  }

  /**
   * Генерация URL для получения партнера по userId
   *
   * @param userId id пользователяы
   * @returns URL для получения партнера по userId
   */
  private getPartnerByUserIdPath(userId: string): string {
    return (
      environment.basePortalApiUrl +
      api.partner.partnerByUserId.replace('{id}', userId)
    );
  }

  /**
   * Генерация URL для работы с виджетом
   *
   * @returns  URL для работы с виджетом
   */
  private getWidgetPath(): string {
    return environment.basePortalApiUrl + api.partner.widget;
  }

  /**
   * Генерация URL для добавления ИМ в ОРТ
   *
   * @returns  URL для работы с виджетом
   */
  private getOrganizationPath(): string {
    return environment.basePortalApiUrl + api.partner.organization;
  }

  /**
   * Генерация URL для получения аналитики предзаказов в ЛК партнера
   *
   * @returns  URL для получения аналитики предзаказов в ЛК партнера
   */
  private getAnalyticPreordersPath() {
    return environment.widgetLogService + api.widget.partner;
  }

  /**
   * Генерация URL для получения CSV аналитики
   *
   * @returns URL для получения аналитики
   */
  private getAnalyticPathCSV() {
    return environment.widgetLogService + api.widget.partnerAnalyticCSV;
  }

  /**
   * Генерация http опций для загрузки файлов
   *
   * @returns http опции
   */
  private getDownloadingHttpOptions() {
    return {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      responseType: 'blob' as 'json'
    };
  }

  /**
   * Генерация URL для получения статистики по филтрам аналитики
   *
   * @returns URL для получения статистики по филтрам аналитики
   */
  private getAnalyticStatPath() {
    return environment.widgetLogService + api.widget.partnerStats;
  }
}
