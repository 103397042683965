import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { api } from '../../config';
import { AuthService } from './auth.service';
import { PartnerService } from './partner.service';
import { Operator, Partner } from '../models';
import { UserFilter } from '../models/UserFilter';
import { UserSort } from '../models/UserSort';
import { UserAccount } from '../models/UserAccounts';
import { PaginableClass } from '../models/PaginableClass';

/**
 * Сервис для работы с пользователями в ЛК Администратора
 */
@Injectable({
  providedIn: 'root'
})
export class ProfileListService {
  /**
   * Http Опции
   */
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getAccessToken(),
      'X-Skip-Interceptor': ''
    })
  };

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private partnerService: PartnerService
  ) {
  }

  /**
   * Создание профиля администратора
   *
   * @param data данныя профиля
   * @returns Наблюдателя за созданием профиля адвинистратора
   */
  add(data): Observable<any> {
    return this.http.post<any>(this.getProfilesPath(), data, this.httpOptions);
  }

  /**
   * Создание профиля Партнера
   *
   * @param partner данные профиля партнера
   * @returns Наблюдателя за созданием профиля Партнера
   */
  addPartnerProfile(partner: Partner): Observable<any> {
    return this.partnerService.add(partner);
  }

  /**
   * Создание профиля оператора
   *
   * @param email почта
   * @returns Наблюдателя за созданием профиля оператора
   */
  addOperatorProfile(email: string): Observable<any> {
    return this.http.post<any>(
      this.createOperatorPath(),
      { email },
      this.httpOptions
    );
  }
  /**
   * Создание профиля оператора
   *
   * @param email почта
   * @returns Наблюдателя за созданием профиля оператора
   */
  addPaymentOperatorProfile(email: string): Observable<any> {
    return this.http.post<any>(
      this.createPaymentOperatorPath(),
      { email },
      this.httpOptions
    );
  }

  /**
   * Обновление профиля оператора
   *
   * @param operator данные оператора
   * @returns Наблюдателя за обновлением профиля оператора
   */
  updateOperatorProfile(operator: Operator): Observable<any> {
    return this.http.put<any>(
      this.updateOperatorPath(operator.id),
      operator,
      this.httpOptions
    );
  }

  /**
   * Получение списка пользователей
   *
   * @param filter фильтр
   * @param sort сортировка
   * @returns Наблюдателя за получением списка пользователей
   */
  getPaginatedProfiles(
    filter: UserFilter,
    sort?: UserSort
  ): Observable<PaginableClass<UserAccount>> {
    const opts = Object.assign(
      { params: filter.toHttpParams() },
      this.httpOptions
    );
    if (sort) {
      Object.assign(opts.params, sort.toHttpParams());
    }
    return this.http.get<PaginableClass<UserAccount>>(
      this.getPaginatedProfilesPath(),
      opts
    );
  }

  /**
   * Получение деталей пользователя по ID
   *
   * @param userId id пользователя
   * @returns Наблюдателя за получением деталей пользователя по ID
   */
  getProfileById(userId: string): Observable<any> {
    return this.http.get<any>(
      this.getProfileByIdPath(userId),
      this.httpOptions
    );
  }

  /**
   * Обновление данных профиля пользователя
   *
   * @param user данные пользователя
   * @returns Наблюдателя за обновлением профиля пользователя
   */
  updateUserData(user: any): Observable<any> {
    return this.http.post<any>(
      this.getUserDataChangePath(),
      user,
      this.httpOptions
    );
  }

  /**
   * Обновление данных об организации партнёра
   *
   * @param partnerId id партнёра
   * @param networkId id новой организации
   * @returns Наблюдателя за обновлением профиля пользователя
   */
  updatePartnerOrganization(partnerId: string, networkId: string): Observable<any> {
    return this.http.post<any>(
      this.getPartnerOrgDataChangePath(partnerId, networkId),
      {},
      this.httpOptions
    );
  }

  /**
   * Блокировка/Разблокировка профиля
   *
   * @param userId id профиля
   * @param blocked флаг блокировки
   * @returns Наблюдателя за блокировкой/разблокировкой профиля
   */
  block(userId: string, blocked: boolean): Observable<any> {
    return this.http.patch<any>(
      this.getBlockProfilePath(userId),
      { blocked },
      this.httpOptions
    );
  }

  /**
   * Генерация URL для создания профиля администратора
   *
   * @returns URL для создания профиля администратора
   */
  private getProfilesPath(): string {
    return environment.basePortalApiUrl + api.user.all;
  }

  /**
   * Генерация URL для получения списка пользователей
   *
   * @returns URL для получения списка пользователей
   */
  private getPaginatedProfilesPath(): string {
    return environment.basePortalApiUrl + api.user.allPaginate;
  }

  /**
   * Генерация URL для получения деталей профиля по id
   *
   * @param userId id профиля
   * @returns URL для получения деталей профиля по id
   */
  private getProfileByIdPath(userId: string): string {
    return (
      environment.basePortalApiUrl + api.user.userById.replace('{id}', userId)
    );
  }

  /**
   * Генерация URL для обновления данных пользователя
   *
   * @returns URL для обновления данных пользователя
   */
  private getUserDataChangePath(): string {
    return (
      environment.basePortalApiUrl + api.account.update
    );
  }

  /**
   * Генерация URL для обновления организации партнёра
   *
   * @param partnerId id партнёра
   * @param networkId id новой организации
   * @returns URL для обновления организации партнёра
   */
  private getPartnerOrgDataChangePath(partnerId: string, networkId: string): string {
    return (
      environment.basePortalApiUrl + api.partner.organizationUpdate
        .replace('{partnerId}', partnerId)
        .replace('{networkId}', networkId)
    );
  }

  /**
   * Генерация URL для блокировки профиля
   *
   * @param userId id профиля
   * @returns URL для блокировки профиля
   */
  private getBlockProfilePath(userId: string): string {
    return (
      environment.basePortalApiUrl + api.user.block.replace('{id}', userId)
    );
  }

  /**
   * Генерация URL для создания профиля оператора
   *
   * @returns URL для создания профиля оператора
   */
  private createOperatorPath(): string {
    return environment.basePortalApiUrl + api.operator.createOperator;
  }
  /**
   * Генерация URL для создания профиля платёжного оператора
   *
   * @returns URL для создания профиля платёжного оператора
   */
  private createPaymentOperatorPath(): string {
    return environment.basePortalApiUrl + api.operator.createPaymentOperator;
  }

  /**
   * Генерация URL для обновления оператора
   *
   * @param id id профиля
   * @returns URL для обновления оператора
   */
  private updateOperatorPath(id: string): string {
    return (
      environment.basePortalApiUrl + api.operator.createOperator + `/${id}`
    );
  }
}
