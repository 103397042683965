/* eslint-disable id-denylist */
import { Serializable } from 'src/app/core/models/Serializable';
import { Country } from '../Country';

export class UserPassportUpdateEvent extends Serializable {
  name: string;
  countryDTO: Country;
  dateOfIssue: string;
  number: number | string;
  patronymic: string;
  series: number | string;
  surname: string;
}
