import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { api } from 'src/app/config/api';
import { Customer, User } from 'src/app/core/models';
import { StoreFilterService } from './store-filter.service';
import { INTERCEPTOR_SKIP_HEADER } from '../headers';
import {
  CHEQUES_REPORT_FILTERS,
  GOODS_REPORT_FILTERS,
  USERS_REPORT_FILTERS,
  USER_DRAFT,
  YANDEX_METRIKS_EVENTS,
} from '../constants';
import { map } from 'rxjs/operators';
import { MetrikaService } from './metrika.service';
/**
 * Сервис авторизации
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * Текущие роли пользователя
   *
   * @type {string[]}
   */
  currentUserRole: string[];
  /**
   * ID текущего пользователя
   *
   * @type {string}
   */
  currentUserId: string;
  /**
   * Subject для проброса изменений в списке ролей пользователя
   *
   * @type {}
   */
  userRoleChange: Subject<any[]> = new Subject<any[]>();
  /**
   * Subject для проброса события получения объекта пользователя
   *
   * @type {Subject<any>}
   */
  private userObtained: Subject<any> = new Subject<any>();
  /**
   * Текущий пользователь
   *
   * @type {User}
   */
  private _currentUser: User;
  /**
   * BehaviourSubject для хранения и проброса события изменения Customer'a
   * Используется для предотвращения повторных запросов
   *
   * @type {BehaviorSubject<Customer>}
   */
  private _curentCustomer$: BehaviorSubject<Customer> =
    new BehaviorSubject<Customer>(null);

  constructor(
    private http: HttpClient,
    private oauthService: OAuthService,
    private storeFilterService: StoreFilterService,
    private metrikeService: MetrikaService,
    @Inject(LOCALE_ID) protected localeId: string,
  ) {
    /**
     * Конфигурация ouath сервиса, параметрами из environment
     */
    this.oauthService.configure({
      loginUrl: environment.auth.loginUrl,
      redirectUri: environment.auth.redirectUri,
      clientId: environment.auth.clientId,
      scope: environment.auth.scope,
      oidc: environment.auth.oidc,
      logoutUrl: environment.auth.logoutUrl,
    });
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.tryLogin({}).then((isSuccessfullLogin) => {
      if (isSuccessfullLogin && environment.yandexMetrikaOn) {
        setTimeout(() => {
          this.metrikeService.reachGoal(
            YANDEX_METRIKS_EVENTS.form_complete_login,
          );
        }, 2500);
      }
    });
    this.userRoleChange.subscribe((roles) => {
      this.currentUserRole = roles;
    });
  }

  /**
   * Геттер наблюдателя за изменением пользователя
   */
  get userObtainedObservable() {
    return this.userObtained.asObservable();
  }

  /**
   * Геттер текущего пользователя
   */
  get currentUser() {
    return this._currentUser;
  }

  /**
   * Геттер BehaviorSubject для простановки текущего покупателя
   */
  get currentCustomer() {
    return this._curentCustomer$;
  }

  /**
   * Геттер текущего покупателя из BehaviorSubject
   */
  get currentCustomerValue() {
    return this._curentCustomer$.value;
  }

  /**
   * Геттер наблюдателя за изменением покупателя
   */
  get currentCustomerObserver() {
    return this._curentCustomer$.asObservable();
  }

  /**
   * Геттер наблюдателя проверки на возможность покупателя иметь чеки
   */
  get currentCustomerHasNoAvailableChecksObserver() {
    return this._curentCustomer$
      .asObservable()
      .pipe(map((c) => c && (!c.passport || !c.paymentDetails?.length)));
  }

  /**
   * Обновление ролей
   *
   * @param roles новые роли
   */
  updateUserRoles(roles: string[]) {
    this.currentUserRole = roles;
    this.userRoleChange.next(roles);
  }

  /**
   * Получение username пользователя
   *
   * @param user пользователь
   */
  getUserName(user: User) {
    this.currentUserId = user.userId;
  }

  /**
   * Запрос на получение пользователя
   *
   * @description Выполняет запрос, запускает простановку и обновление параметров пользователя,
   * прокидывает событие получения пользователя
   * @returns Наблюдателя за получением пользователя
   */
  obtainAuthUser(): Observable<User> {
    return new Observable((observer) => {
      this.http
        .get<User>(this.getAuthUserPath(), this.getSecuredHttpOptions())
        .subscribe(
          (user) => {
            if (user && user?.userId) {
              this.updateUserRoles(user.roles);
              this.getUserName(user);
              observer.next(user);
              this._currentUser = user;
              this.userObtained.next(user);
            } else {
              this.logout();
            }
          },
          () => this.logout(),
        );
    });
  }

  /**
   * Инициализация флоу получения токена
   */
  obtainAccessToken() {
    this.oauthService.initImplicitFlow();
  }

  /**
   * Признак залогинен ли пользователь
   *
   * @returns признак залогинен ли пользователь
   */
  isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  // @todo редирект на логаут должен работать из angular-oauth2-oidc, проверить после апдейта на 9 версию ангуляра
  // и обновления версии билиотеки
  // @todo https://github.com/manfredsteyer/angular-oauth2-oidc/issues/263
  /**
   * Выход из системы
   *
   * @description очищает переменные в локальном хранилище, очищает пользователя и покупателя,
   * редиректит на портал авторизации
   */
  logout(): void {
    (window as any).global = window;
    localStorage.removeItem(USER_DRAFT);
    localStorage.removeItem(CHEQUES_REPORT_FILTERS);
    localStorage.removeItem(GOODS_REPORT_FILTERS);
    localStorage.removeItem(USERS_REPORT_FILTERS);
    this._currentUser = undefined;
    this._curentCustomer$.next(null);
    const langParam = this.localeId === 'en' ? '' : `?lang=${this.localeId}`;
    this.oauthService.logoutUrl = environment.auth.logoutUrl + langParam;
    this.oauthService.logOut(false);
    window.location.href = environment.auth.logoutUrl + langParam;
    this.storeFilterService.cleanFilteringByStore();
  }

  /**
   * Получение токена
   *
   * @returns токен
   */
  public getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  /**
   * Получение хедера с токеном в ключе Authorization
   *
   * @returns хедер с токеном в ключе Authorization
   */
  public getSecuredHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.getAccessToken(),
      }),
    };
  }

  /**
   * Получение хедера с токеном в ключе Authorization для скачивания архива
   *
   * @returns хедер с токеном в ключе Authorization для скачивания архива
   */
  public getSecuredHttpOptionsForFile() {
    return {
      headers: new HttpHeaders({
        Accept: 'application/zip',
        Authorization: 'Bearer ' + this.getAccessToken(),
      }),
      responseType: 'arraybuffer' as 'json',
    };
  }

  /**
   * Получение капчи
   *
   * @description запрашивает капчу либо для публичной части, либо для авторизованого пользователя
   */
  public getCaptcha() {
    if (this.isLoggedIn()) {
      return this.getSecurityCaptcha();
    }
    return this.getPublicCaptcha();
  }

  /**
   * Получение HttpOptions для авторизованного состояния
   *
   * @returns опции для авторизованного пользователя
   */
  public getHttpOptionsByAuthState() {
    if (this.isLoggedIn()) {
      return this.getSecuredHttpOptions();
    }
    return {};
  }

  /**
   * Полуение опций с ключом X-Skip-Interceptor
   *
   * @returns опции с ключом X-Skip-Interceptor
   */
  public getHttpOptionsByAuthStateWithSkipHeader() {
    if (!this.isLoggedIn()) {
      return INTERCEPTOR_SKIP_HEADER;
    }
    const options = this.getSecuredHttpOptions();
    options.headers.append('X-Skip-Interceptor', '');
    return options;
  }

  /**
   * Получение капчи для публичной части портала
   */
  getPublicCaptcha() {
    const salt = new Date().getTime();
    return this.http.get(`${this.getCaptchaPath()}?${salt}`, {
      responseType: 'blob',
      withCredentials: true,
      headers: INTERCEPTOR_SKIP_HEADER,
    });
  }

  /**
   * Получение капчи для авторизованного пользователя
   */
  private getSecurityCaptcha() {
    return this.http.get(this.getCaptchaPath(), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.getAccessToken(),
      }),
      responseType: 'blob',
      withCredentials: true,
    });
  }

  /**
   * Генерация URL для запроса капчи
   *
   * @returns URL для запроса капчи
   */
  private getCaptchaPath(): string {
    return environment.baseAccountingApiUrl + api.shoppers.captcha;
  }

  /**
   * Генерация URL для запроса текущего пользователя
   *
   * @returns URL для запроса текущего пользователя
   */
  private getAuthUserPath(): string {
    return environment.basePortalApiUrl + api.customer.currentUser;
  }
}
