/* eslint-disable max-len */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/env';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { api } from 'src/app/config';
import { AuthService } from './auth.service';

@Injectable()
export class WidgetSharedService {
  /**
   * Глобальные настройки
   */
  globalSettings;
  /**
   * Глобальные настройки
   */
  localizations;

  /**
   * Behaviour для наблюдений за изменениями настроек
   */
  settingChanged = new BehaviorSubject<any>(null);
  /**
   * Behaviour для наблюдений за изменениями локализация
   */
  localizationChanged = new BehaviorSubject<any>(null);

  /**
   * Глобальные стили
   */
  cssJson: any;

  /**
   * Изменения текстовок
   */
  textChanges: any[] = [];
  /**
   * Http Опции
   */
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getAccessToken()
    })
  };

  constructor(private http: HttpClient, private authService: AuthService) {}
  /**
   * Геттер наблюдателя за изменением настроек
   */
  getSettingsChanged() {
    return this.settingChanged.asObservable();
  }

  /**
   * Геттер наблюдателя за изменением локализаций
   */
  getLocalizationChanged() {
    return this.localizationChanged.asObservable();
  }

  /**
   * Геттер текущего значения
   *
   * @returns текущее значение
   */
  getSettinsChangedValue() {
    return this.settingChanged.value;
  }

  /**
   * Геттер текущего значения локализации
   *
   * @returns текущее значение
   */
  getLocalizationChangedValue() {
    return this.localizationChanged.value;
  }

  /**
   * Простановка стилей
   *
   * @param styles стили
   */
  setStyles(styles: any) {
    if (!this.globalSettings) {
      this.globalSettings = {};
    }
    this.globalSettings = styles;
    this.settingChanged.next(this.globalSettings);
  }

  /**
   * Сеттер глобальных стилей
   *
   * @param cssJson стили
   */
  setCss(cssJson: any) {
    this.cssJson = cssJson;
  }
  /**
   * Простановка глобальных настроек
   *
   * @param globalSettings глобальные настройки
   */
  setGlobalSettings(globalSettings) {
    if (globalSettings) {
      this.globalSettings = globalSettings;
      this.settingChanged.next(this.globalSettings);
    }
  }

  /**
   * Простановка локализации
   *
   * @param localizations локализация
   */
  setLocalization(localizations) {
    if (localizations) {
      this.localizations = localizations;
      this.localizationChanged.next(this.localizations);
    }
  }
  /**
   * Получение глобальной конфигурации виджета
   *
   * @param isPortal признак портала
   * @returns Наблюдателя за получением глобальной конфигурации
   */
  getGlobalWidgetConfig(isPortal: boolean = false) {
    return this.http.get(this.getGlobalWidgetPath(isPortal), this.httpOptions);
  }

  /**
   * Создание нового конфига виджета
   *
   * @param data данные для виджета
   */
  postSettings(data: any) {
    return this.http.post(this.getGlobalWidgetPath(), data, this.httpOptions);
  }

  /**
   * Обновление конфига виджета
   *
   * @param data данные для виджета
   */
  updateSettings(data: any) {
    return this.http.put(
      `${this.getGlobalWidgetPath()}`,
      data,
      this.httpOptions
    );
  }

  /**
   * Обновление локализации
   *
   * @param localization локализация
   * @param changes изменения
   */
  updateLocalization(localization: any, changes: any) {
    this.localizations = localization;
    this.localizationChanged.next(this.localizations);
    this.textChanges.push(changes);
  }

  /**
   * Получение локализаций
   *
   * @returns Наблюдателя за получением локализаций
   */
  getLocalizations() {
    return this.http.get(this.getLocalizationPath(), this.httpOptions).pipe(
      map((response: any) => {
        if (!response || !response.items) {
          return [];
        }
        const items = response.items;
        const keys = Object.keys(items);
        const locales = [];
        keys.forEach(key => {
          const itemKeys = Object.keys(items[key]);
          const mappedObject = {
            key
          };
          itemKeys.forEach(itemKey => {
            Object.assign(mappedObject, {
              [itemKey]: items[key][itemKey]
            });
          });
          locales.push(mappedObject);
        });
        return locales;
      })
    );
  }

  /**
   * Очистка изменений
   */
  clearChanges() {
    this.textChanges = [];
  }

  /**
   * Сохранение локализации
   */
  saveChanges() {
    const saveData = {};
    this.textChanges.forEach((item: any) => {
      Object.assign(saveData, {
        [item.key]: {
          locales: item.locales,
          system: item.system
        }
      });
    });
    return this.http.put(
      this.getLocalizationPath(),
      saveData,
      this.httpOptions
    );
  }

  /**
   * Генерация URL для получения конфига виджета
   *
   * @param isPortal признак портала
   * @returns URL для получения конфига виджета
   */
  private getGlobalWidgetPath(isPortal: boolean = false) {
    // return environment.widgetPortalService + api.widget.sendPath;

    return (
      (isPortal
        ? environment.basePortalApiUrl
        : environment.widgetPortalService) + api.widget.widgetPath
    );
  }
  /**
   * Генерация URL для получения локализаций
   *
   * @returns URL для получения локализаций
   */
  private getLocalizationPath() {
    return environment.widgetPortalService + api.widget.locale;
  }
}
