import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PartnerService, TradingNetworkService } from '@app/core/services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ShopInformationComponent } from '../shop-information/shop-information.component';
import { AbstractComponent } from '@app/core/classes';
import { takeUntil, finalize, map } from 'rxjs/operators';
import { commonScss } from '../widget-settings/common.styles';
import {
  defaultBtnSizes,
  defaultStyles,
  defaultTabSize,
  middleLogoStyle,
  rightBigCloseBtn,
  rightBottomTabBtn,
  tabButton,
} from '../widget-settings/constants';
import { mainRightBtm } from '../widget-settings/constants';
import { Router } from '@angular/router';
import { WidgetConfig } from '@app/core/models/Widget/WidgetConfig';
import { TradingInternetShop } from '@app/core/models/TradingInternetShop';
import { environment } from '@app/env';
import { WidgetSharedService } from '@app/core/services/widget.shared.service';
import { OrganizationService } from '@app/core/services/organization.service';
import { TradingNetworkOrg } from '@app/core/models/TradingNetworkOrg';

@Component({
  selector: 'app-create-edit-widget-modal',
  templateUrl: './create-edit-widget-modal.component.html',
  styleUrls: ['./create-edit-widget-modal.component.scss'],
})
export class CreateEditWidgetModalComponent
  extends AbstractComponent
  implements OnInit {
  /**
   * Данные виджета
   *
   * @type {WidgetConfig}
   */
  @Input() widgetData: WidgetConfig;
  /**
   * Данные ИМ
   *
   * @type {TradingInternetShop}
   */
  @Input() shopData: TradingInternetShop;
  /**
   * id ОРТ
   *
   * @type {string}
   */
  @Input() tradingNetworkId: string;
  /**
   * Признак администратора
   *
   * @type {boolean}
   */
  @Input() isAdmin;

  /**
   * Признак редактирования
   *
   * @type {boolean}
   */
  @Input() isEdit;

  /**
   * Конфигурация виджета
   *
   * @type {WidgetConfig}
   */
  @Input() widgetConfig: WidgetConfig;
  /**
   * Тригер обновления таблицы после закрытия
   *
   * @type {string}
   */
  @Output() updated = new EventEmitter();
  /**
   * Событие что произошло обновления магазина
   *
   * @type {string}
   */
  @Output() updatedShop = new EventEmitter();
  /**
   * Компонент редактирования данных магазина
   *
   * @type {PassportEditComponent}
   */
  @ViewChild('shopInfo') shopInfoComponent: ShopInformationComponent;
  /**
   * Объект перехода на след. шаг
   *
   * @type {Subject<void>}
   */
  nextStepEvent: Subject<void> = new Subject<void>();
  /**
   * Признак загрузки данных
   *
   * @type {boolean}
   */
  loading: boolean;

  /**
   * Текущий шаг cоздания виджета
   *
   * @type {number}
   */
  activeStep = 0;
  /**
   * Объект содержащий информацию о текущем задизейбленном шаге
   *
   * @type {any}
   */
  disabledStep: {
    value: boolean;
    step: number;
  };

  /**
   * Процесс сохранения данных о магазине
   *
   * @type {boolean}
   */
  saveShopInfo = false;

  /**
   * URL для хранения скриптов и стилей
   *
   * @type {string}
   */
  minioUrl = '/media/tax-free-widget/';
  /**
   * Скрипт инициализации виджета
   *
   * @type {string}
   */
  script: string;
  /**
   * Shop ID
   *
   * @type {string}
   */
  shopId: string;
  /**
   * secretKey
   *
   * @type {string}
   */
  secretKey: string;
  /**
   * Скрипт для сбора не размеченных данных
   *
   * @type {string}
   */
  customScript: string;
  /**
   * Признак отображения успешного добавления виджета у админа
   *
   * @type {boolean}
   */
  showSuccess = false;
  /**
   * Глобальные стили
   *
   * @type {any}
   */
  globalConfig: any;
  /**
   * Список ОПОРТ
   *
   * @type {TradingNetworkOrg[]}
   */
  oports: TradingNetworkOrg[];
  constructor(
    private partnerService: PartnerService,
    private router: Router,
    private tradingService: TradingNetworkService,
    private widgetSharedService: WidgetSharedService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private organizationService: OrganizationService,
  ) {
    super();
  }

  /**
   * Хук инициализации
   */
  ngOnInit() {
    if (!this.isAdmin) {
      this.saveShopInfo = true;
      this.organizationService
        .getOrganizations()
        .pipe(
          map((orgs) => orgs.filter((org) => !org.online)),
          finalize(() => (this.saveShopInfo = false)),
        )
        .subscribe((orgs) => {
          this.oports = orgs;
        });
    }
    this.widgetSharedService.getGlobalWidgetConfig(true).subscribe((val) => {
      this.globalConfig = val;
    });
    this.showTab(this.activeStep);
    if (this.widgetData) {
      this.partnerService
        .getWidgetById(this.widgetData.id)
        .subscribe((widget) => {
          this.widgetConfig = widget;
        });
    }
    if (this.shopData) {
      this.tradingService
        .getWidgetById(this.shopData.id)
        .subscribe((widget) => {
          this.widgetConfig = widget;
        });
    }
  }
  /**
   * Закрытие модального окна
   */
  close(): void {
    if (this.shopId) {
      this.updated.emit(true);
    }
    this.activeModal.close();
  }

  /**
   * Метод отображения таба
   *
   * @param n - номер таба
   */
  showTab(n) {
    const tabs = document.getElementsByClassName(
      'tab',
    ) as HTMLCollectionOf<HTMLElement>;
    const steps = document.getElementsByClassName(
      'step-item',
    ) as HTMLCollectionOf<HTMLElement>;
    steps[n].classList.add('active');
    if (this.activeStep > 0) {
      steps[n - 1].classList.add('finished');
    }
    tabs[n].style.display = 'flex';
  }
  /**
   * Метод перехода по шагам
   *
   * @param step - номер таба
   */
  toStep(step: number) {
    this.nextStepEvent.next();
    // при шаге назад сбрасываем текущий признак блокировки
    if (step < 0) {
      this.disabledStep = null;
    }
    if (
      this.disabledStep &&
      this.disabledStep.value &&
      step !== -1 &&
      this.disabledStep.step === this.activeStep
    ) {
      return false;
    }

    if (step > 0 && this.activeStep === 0 && this.shopInfoComponent) {
      this.createShop();
    } else if (step > 0 && this.activeStep === 2) {
      this.saveScript();
    } else {
      this.showNextTab(step);
    }
  }

  /**
   * Обработчик обновления кастомного скрипта для глубокой интеграции
   *
   * @param script новый скрипт
   */
  updateCode(script: any) {
    this.customScript = script ? script.code : null;
  }

  /**
   * Сохранение скрипта
   */
  saveScript() {
    if (!this.customScript && !this.isEdit) {
      this.showSuccess = true;
      return;
    }
    this.loading = true;
    const data = {
      changeableParts: this.widgetConfig.changeableParts,
      cssJson: this.widgetConfig.cssJson,
      name: this.widgetConfig.name,
      shopId: this.widgetConfig.shopId,
      version: 0,
      script: this.customScript,
    };
    if (this.isAdmin) {
      data['tradingNetworkId'] = this.tradingNetworkId;
    }
    const observables = this.tradingService.updateWidgetById(
      this.widgetConfig.id,
      data,
    );
    observables
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.loading = false)),
      )
      .subscribe(() => {
        this.showSuccess = true;
      });
  }

  /**
   * Переход на следующую вкладку
   *
   * @param step номер шага
   */
  showNextTab(step: number) {
    const tabs = document.getElementsByClassName(
      'tab',
    ) as HTMLCollectionOf<HTMLElement>;
    const steps = document.getElementsByClassName(
      'step-item',
    ) as HTMLCollectionOf<HTMLElement>;
    if (tabs[this.activeStep]) {
      tabs[this.activeStep].style.display = 'none';
    }
    steps[this.activeStep].classList.remove('active');
    this.activeStep = this.activeStep + step;
    this.showTab(this.activeStep);
  }

  /**
   * Добавление интернет магазина
   */
  createShop() {
    if (!this.shopInfoComponent) {
      return;
    }
    this.saveShopInfo = true;
    const data = this.shopInfoComponent.form.value;
    if (this.isAdmin) {
      this.createAdminShop(data);
    } else {
      this.createPartnerShop(data);
    }
  }

  /**
   * Создание магазина у партнера
   *
   * @param data данные формы
   */
  createAdminShop(data: any) {
    const observable = this.shopData
      ? this.tradingService.updateShopForTrading(
          this.shopData.id,
          {
            email: data.email,
            name: data.shopName,
            url: data.shopUrl,
            chequeUrl: data.chequeUrl,
            widgetStatusTime: data.widgetStatusTime,
            chequeRequestTime: data.chequeRequestTime,
            widgetLocation: data.widgetLocation,
            online: true,
            parentId: this.shopData.parentId,
          },
          this.tradingNetworkId,
      )
      : this.tradingService.createShopForTrading(
          {
            email: data.email,
            name: data.shopName,
            url: data.shopUrl,
            chequeUrl: data.chequeUrl,
            widgetStatusTime: data.widgetStatusTime,
            chequeRequestTime: data.chequeRequestTime,
            widgetLocation: data.widgetLocation,
            online: true,
          },
          this.tradingNetworkId,
      );
    observable.pipe(takeUntil(this.destroy$)).subscribe(
      (val: any) => {
        this.shopId = val.id;
        this.secretKey = val.secretKey;
        this.generateScript(this.shopId, !this.shopData);
        this.shopData = val;
        this.updatedShop.emit(this.shopData);
      },
      () => {
        this.saveShopInfo = false;
      },
    );
  }
  /**
   * Создание магазина у партнера
   *
   * @param data данные формы
   */
  createPartnerShop(data: any) {
    const payload: WidgetConfig = {
      email: data.email,
      name: data.shopName,
      url: data.shopUrl,
      widgetLocation: data.widgetLocation,
      chequeUrl: data.chequeUrl,
      online: true,
      parentId: data.oport,
    };
    const observable = this.widgetData
      ? this.partnerService.updateShopInfo(this.widgetData.id, payload)
      : this.partnerService.saveShopInfo(payload);
    observable.pipe(takeUntil(this.destroy$)).subscribe(
      (val: any) => {
        this.shopId = val.id;
        this.secretKey = val.secretKey;
        this.generateScript(this.shopId, !this.widgetData);
        this.widgetData = val;
      },
      () => {
        this.saveShopInfo = false;
      },
    );
  }
  /**
   * Генерация скрипта инициализации
   *
   * @param shopId id магазина
   * @param isCreate признак создания магазина
   */
  generateScript(shopId: string, isCreate: boolean) {
    this.partnerService.getRawScript().subscribe((val: any) => {
      if (!val) {
        return;
      }
      this.script = val.replace('`${shopId}`', '\'' + shopId + '\'');
      this.script = this.script.replace(
        '\'initialScriptPath\'',
        '\'' + `${environment.widgetInitialScriptPath}?timestamp=` + '\'',
      );
      if (isCreate) {
        this.generateStyles(shopId);
      } else {
        if (!this.widgetConfig) {
          this.saveShopInfo = false;
          return;
        }
        this.replaceCustomFunctionUrl(this.widgetConfig.scriptUrl);
        this.replaceStyleUrl(this.widgetConfig.widgetConfigUrl);
        this.showNextTab(1);
        this.saveShopInfo = false;
      }
    });
  }
  /**
   * Генерация дефолтных стилей
   *
   * @param shopId id магазина
   */
  generateStyles(shopId: string) {
    let styles;
    if (this.globalConfig) {
      styles = this.globalConfig.cssJson;
    } else {
      styles = commonScss;
      [
        tabButton,
        rightBottomTabBtn,
        mainRightBtm,
        middleLogoStyle,
        defaultTabSize,
        defaultBtnSizes,
        rightBigCloseBtn,
        defaultStyles,
      ].forEach((item) => {
        styles = styles + item;
      });
    }
    /**
     * Сохранение стилей
     */
    const data = {
      changeableParts: this.globalConfig
        ? this.globalConfig.changeableParts
        : null,
      cssJson: styles,
      name: `override_${shopId}.scss`,
      shopId,
      version: 0,
    };
    if (this.isAdmin) {
      data['tradingNetworkId'] = this.tradingNetworkId;
    }
    const observables = this.isAdmin
      ? this.tradingService.saveWidgetData(data)
      : this.partnerService.saveWidgetData(data);
    observables.subscribe((res: any) => {
      if (!res) {
        return;
      }
      this.widgetConfig = res;
      this.replaceStyleUrl(res.widgetConfigUrl);
      this.replaceCustomFunctionUrl(res.scriptUrl);
      this.showNextTab(1);
      this.saveShopInfo = false;
    });
  }

  /**
   * Замена url стилей
   *
   * @param widgetConfigUrl url из конфига
   */
  replaceStyleUrl(widgetConfigUrl: string) {
    this.script = this.script.replace(
      '`${stylesUrl}`',
      '\'' + widgetConfigUrl + '\'',
    );
  }

  /**
   * Замена url кастомной фукнции
   *
   * @param customUrl url из конфига
   */
  replaceCustomFunctionUrl(customUrl: string) {
    this.script = this.script.replace(
      '`${customScriptUrl}`',
      '\'' + customUrl + '\'',
    );
  }
  /**
   * Метод дизейбла перехода на след. щаг
   *
   * @param value - задизейблен ли шаг
   * @param step - номер шага
   */
  disableNextSteps(value: boolean, step: number) {
    setTimeout(() => {
      this.disabledStep = { value, step };
    }, 0);
  }

  /**
   * Признак блокировки кнопки
   */
  isButtonDisabled() {
    return (
      (this.disabledStep &&
        this.disabledStep.value &&
        this.disabledStep.step === this.activeStep) ||
      this.saveShopInfo
    );
  }

  /**
   * Открытие настроек стилей виджета
   */
  redirectToSettings() {
    this.router.navigate(
      this.isAdmin
        ? ['admin/widget-settings', this.shopId]
        : ['partner/widget-settings', this.shopId],
    );
    this.close();
    this.modalService.dismissAll();
  }
}
