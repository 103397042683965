import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { api } from '../../config/api';
import { Checkpoint } from '../models';

/**
 * Сервис для получения пунктов пропуска на публичной части портала
 */
@Injectable({ providedIn: 'root' })
export class CheckPointsService {
  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) protected localeId: string
  ) {}

  /**
   * Получение списка пунктов пропуска
   */
  public getAllCheckPoints(): Observable<Checkpoint[]> {
    const options = { params: new HttpParams() };
    return new Observable(observer => {
      this.http
        .get<Checkpoint[]>(this.getAllCheckPointsPath(), options)
        .subscribe(checkPoints => observer.next(checkPoints));
    });
  }

  /**
   * Получение пункта пропуска
   *
   * @param id id пункта пропуска
   */
  public getCheckPoint(id: string): Observable<any> {
    return new Observable(observer => {
      this.http
        .get<any>(this.getCheckPointPath(id))
        .subscribe(checkPoint => observer.next(checkPoint));
    });
  }

  /**
   * Получение url для списка пунктов пропуска
   *
   * @returns url запроса
   */
  private getAllCheckPointsPath(): string {
    return environment.baseAccountingApiUrl + api.checkpoints.checkpoints;
  }

  /**
   * Получение url для пункта пропуска
   *
   * @param id id пункта пропуска
   * @returns url запроса
   */
  private getCheckPointPath(id: string): string {
    return (
      environment.baseAccountingApiUrl +
      api.checkpoints.checkpoint.replace('{id}', id)
    );
  }
}
